// interface.
import { IRoute } from '@routes/routes';

// types.
import { polygonsPath } from './routes.types';

// components.
import { PolygonsDashboard } from '../modules/PolygonsDashboard/PolygonsDashboard';

export const routesPolygonsPage: IRoute[] = [
  {
    id: 'dashboard-tools',
    path: `${polygonsPath}`,
    element: <PolygonsDashboard />,
  },
];
