// Libraries
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiAcquisitionsPortal = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.ENV_PRICING_ROCKET_API_URL as string,
    prepareHeaders: (headers: Headers) => {
      headers.set(
        'x-api-key',
        process.env.ENV_PRICING_ROCKET_API_KEY as string
      );
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: () => ({}),
  reducerPath: 'apiAcquisitionsPortal',
  tagTypes: ['Polygons', 'MedianZones', 'getSavedIntersections'],
});
