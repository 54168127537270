import { polygon } from '@turf/turf';
import { Polygon, Feature } from 'geojson';

export function latLngArrayToGeoJSON(
  latLngArray: google.maps.LatLngLiteral[]
): Feature<Polygon> {
  const coordinates = latLngArray.map(point => [point.lng, point.lat]);

  if (
    coordinates[0][0] !== coordinates[coordinates.length - 1][0] ||
    coordinates[0][1] !== coordinates[coordinates.length - 1][1]
  ) {
    coordinates.push(coordinates[0]);
  }

  return polygon([coordinates]);
}

export function GeoJSONToLatLngArray(
  geoJSON: Feature<Polygon>
): google.maps.LatLngLiteral[] {
  const coordinates = geoJSON.geometry.coordinates[0].map(
    (point: number[]) => ({
      lat: point[1],
      lng: point[0],
    })
  );

  return coordinates;
}
