// libraries.
import { useSelector } from 'react-redux';

// selectors.
import { getIsHoveredSidebar } from '../store/slices/sidebar-slice';

// assets.
import { IconHabi } from '@assets/icons';
import { useNavigate } from 'react-router-dom';
import { robotoRegular } from '@habitech/shared';

export const ButtonLogo = ({ path }: { path?: string }) => {
  const navigate = useNavigate();

  const isHoveredSidebar = useSelector(getIsHoveredSidebar);

  const handleNavigate = () => {
    navigate(path || '/home');
  };

  return (
    <div
      className="d-flex align-items-center gap-2 btn p-1"
      onClick={handleNavigate}
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          width: '40px',
          height: '40px',
        }}
      >
        <IconHabi />
      </div>
      {isHoveredSidebar && (
        <div
          className="fw-bold text-white"
          style={{ fontFamily: robotoRegular, fontSize: '13px' }}
        >
          Habi
        </div>
      )}
    </div>
  );
};
