// libraries.
import { Accordion } from 'react-bootstrap';

// styles.
import { WrapperComment } from './styles';
import { EditPolygonComment } from './EditPolygonComment';

export const PolygonComment = ({
  comment,
  polygonId,
}: {
  comment: string | undefined;
  polygonId: string;
}) => {
  return (
    <WrapperComment>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Comentario</Accordion.Header>
          <Accordion.Body>
            <EditPolygonComment comment={comment} polygonId={polygonId} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </WrapperComment>
  );
};
