/*
 * Get path from polygon
 * @param {google.maps.Polygon} polygon
 * @returns {Array<{ lat: number, lng: number }>}
 */
export const getPathFromPolygon = (polygon: google.maps.Polygon) => {
  if (!polygon) {
    throw new Error('Polygon is required');
  }

  const path = polygon
    .getPath()
    .getArray()
    .map((latlng: google.maps.LatLng) => ({
      lat: latlng.lat(),
      lng: latlng.lng(),
    }));

  return path;
};
