// libraries.
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

// slices.
import { apiAcquisitionsPortal } from '@store/apiSlices/apiSlices';

// interfaces.
import { ModelError } from '@store/apiSlices/interfaces/interfaces';

interface IntersectionsParams {
  country?: string;
  median_zone: number;
}

interface Polygon {
  polygon_id: number;
  polygon: google.maps.LatLngLiteral[];
}

export interface Intersection {
  polygon_interception: google.maps.LatLngLiteral[];
  polygons: Polygon[];
}

interface IntersectionsResponse {
  body: Intersection[];
  status_code: number;
  success: boolean;
}

export const intersections = apiAcquisitionsPortal.injectEndpoints({
  endpoints: builder => ({
    getIntersections: builder.query({
      query: ({ country, median_zone }: IntersectionsParams) => {
        return `/intersection?country=${country}&median_zone=${median_zone}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: 'getIntersections',
        };
        return modelError;
      },
      transformResponse: (response: IntersectionsResponse): Intersection[] => {
        return response.body;
      },
    }),
  }),
});

export const { useGetIntersectionsQuery } = intersections;
