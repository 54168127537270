// libraries.
import { CardPolygons } from '@domains/save-polygons';

// components.
import { TitlePolygons } from '@domains/save-polygons/components/ListPolygons/TitlePolygons';

// styles.
import { WrapperPolygons } from './styles';

export const PolygonsTool = () => {
  return (
    <div
      style={{
        height: 'calc(100vh - 75px)',
        overflowY: 'auto',
      }}
    >
      <WrapperPolygons>
        <TitlePolygons />
        <CardPolygons />
      </WrapperPolygons>
    </div>
  );
};
