// libraries.
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDebounce } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// selector.
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';

// components.
import { NidInput } from './components/NidInput/NidInput';
import { CoordinatesInputs } from './components/CoordinateInputs/CoordinatesInputs';

// slice.
import { setMedianZoneCode } from '@domains/median-zones/store/slices/medianZones.slice';
import { useGetSearchMedianZonesQuery } from '@domains/median-zones/store/api-slices/searchMedianZones.endpoints';

// styles.
import { WrapperSearchByNid } from '../styles';

export const NidAndCoordsInputs = () => {
  const dispatch = useDispatch();
  const country = useSelector(getCountry);

  const [nid, setNid] = useState<string>('');
  const [lat, setLat] = useState<string>('');
  const [lng, setLng] = useState<string>('');

  const debouncedNid = useDebounce(nid, 1000);
  const debouncedLat = useDebounce(lat, 1000);
  const debouncedLng = useDebounce(lng, 1000);

  const coordinates = debouncedLat && debouncedLng;

  const { data, isFetching } = useGetSearchMedianZonesQuery(
    {
      country,
      nid: debouncedNid,
      lat: coordinates && debouncedLat,
      lng: coordinates && debouncedLng,
    },
    {
      skip: !debouncedNid && !coordinates,
    }
  );

  const handleCoordinateChange = (name: 'lat' | 'lng', value: string) => {
    if (nid) setNid('');
    if (name === 'lat') setLat(value);
    if (name === 'lng') setLng(value);
  };

  const handleNidChange = (value: string) => {
    if (lat || lng) {
      setLat('');
      setLng('');
    }
    setNid(value);
  };

  useEffect(() => {
    if (data?.median_zone_id) {
      dispatch(setMedianZoneCode(data.median_zone_id));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (isFetching) {
      toast.loading('Buscando zona mediana...', {
        toastId: 'loadingMedianZone',
      });
    } else {
      toast.dismiss('loadingMedianZone');
    }
  }, [isFetching]);

  return (
    <WrapperSearchByNid>
      <Form.Group controlId="formBasicEmail" className="w-100">
        <NidInput value={nid} onChange={handleNidChange} />
        <CoordinatesInputs
          lat={lat}
          lng={lng}
          onLatChange={value => handleCoordinateChange('lat', value)}
          onLngChange={value => handleCoordinateChange('lng', value)}
        />
      </Form.Group>
    </WrapperSearchByNid>
  );
};
