// libraries.
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// routes.
import { IRoute, routes } from './routes';

// components.
import { ProtectedRoute } from './ProtectedRoute';

export const RouterApp = () => {
  const renderRoutes = (routes: IRoute[]) => {
    return routes.map(route => {
      const element = route.protected ? (
        <ProtectedRoute>{route.element}</ProtectedRoute>
      ) : (
        route.element
      );
      return (
        <Route key={route.id} path={route.path} element={element}>
          {route.children && renderRoutes(route.children)}
        </Route>
      );
    });
  };

  return (
    <Router>
      <Routes>{renderRoutes(routes)}</Routes>
    </Router>
  );
};
