// libraries.
import { useDispatch } from 'react-redux';

// components.
import { ButtonSidebar } from '@shared';

// assets.
import { IconToolPolygon } from '@assets/icons';

// slices.
import { selectSidebarButton } from '@shared/Sidebar/store/slices/sidebar-slice';

// interfaces.
import { SidebarPolygonTool } from '../../../interfaces/sideBar.types';

export const ButtonPolygonsInMedianZone = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(selectSidebarButton(SidebarPolygonTool.polygonsInMedianZones));
  };

  return (
    <ButtonSidebar
      icon={<IconToolPolygon />}
      text="Polígonos"
      onClick={handleClick}
    />
  );
};
