// libraries.
import React from 'react';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// slices.
import { getSelectedSidebarButton } from '@shared/Sidebar/store/slices/sidebar-slice';

// components.
import { PolygonsTool } from './components/PolygonsTool/PolygonsTool';
import { MedianZonesTool } from './components/MedianZonesTool/MedianZonesTool';
import { IntersectionsTool } from './components/IntersectionsTool/IntersectionsTool';
import { SidebarPolygonTool } from '../../interfaces/sideBar.types';

const Layout = ({ children }: { children: React.ReactNode }) => (
  <Col lg={3} className="p-0">
    {children}
  </Col>
);

export const LayoutPolygonTool = () => {
  const selectedSidebarButton = useSelector(getSelectedSidebarButton);

  const isMedianZonesTool =
    selectedSidebarButton === SidebarPolygonTool.medianZones;

  const isPolygonsInMedianZonesTool =
    selectedSidebarButton === SidebarPolygonTool.polygonsInMedianZones;

  const isPolygonsIntersections =
    selectedSidebarButton === SidebarPolygonTool.polygonsIntersections;

  if (isMedianZonesTool) {
    return (
      <Layout>
        <MedianZonesTool />
      </Layout>
    );
  }

  if (isPolygonsInMedianZonesTool) {
    return (
      <Layout>
        <PolygonsTool />
      </Layout>
    );
  }

  if (isPolygonsIntersections) {
    return (
      <Layout>
        <IntersectionsTool />
      </Layout>
    );
  }

  return null;
};
