// components.
import { TitlePolygons } from '@domains/save-polygons/components/ListPolygons/TitlePolygons';
import { PolygonCardsViewer } from '@domains/save-polygons/components/PolygonsViewer/PolygonCardsViewer/PolygonCardsViewer';
import { WrapperPolygons } from '@pages/PolygonPage/modules/PolygonsDashboard/components/LayoutPolygonTool/components/PolygonsTool/styles';

export const ViewerPolygons = () => {
  return (
    <div
      style={{
        height: 'calc(100vh - 75px)',
        overflowY: 'auto',
      }}
    >
      <WrapperPolygons>
        <TitlePolygons />
        <PolygonCardsViewer />
      </WrapperPolygons>
    </div>
  );
};
