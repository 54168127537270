import { IntersectionListHandler } from '@domains/save-polygons/interfaces';
import { PolygonObject } from '../../api-slices/getPolygons.endpoints';

export const getIntersectionsByZone = (
  intersections: IntersectionListHandler[],
  listPolygons: PolygonObject[]
) => {
  return intersections.filter(intersection =>
    intersection.polygonIds?.some(polygonId =>
      listPolygons.some(polygon => polygon.polygon_id === polygonId)
    )
  );
};
