import {
  getIntersectionListHandler,
  getPolygonsForCards,
} from '@domains/save-polygons';
import { RootState } from '@store';

// utils.
import { getIntersectionsByZone } from '../utils/intersectionsByZone';

export const getIntersectionCountsWithoutSave = (state: RootState) => {
  const intersectionListHandler = getIntersectionListHandler(state);
  const listPolygons = getPolygonsForCards(state);

  const intersectionsByZone = getIntersectionsByZone(
    intersectionListHandler,
    listPolygons
  );

  let countWithoutId = 0;
  let countWithoutComment = 0;
  let countWithoutPolygon = 0;

  intersectionsByZone.forEach(intersection => {
    if (!intersection.id) {
      countWithoutId++;
    }
    if (!intersection.comment) {
      countWithoutComment++;
    }
    if (!intersection.priorityPolygonId) {
      countWithoutPolygon++;
    }
  });

  return {
    countWithoutId,
    countWithoutComment,
    countWithoutPolygon,
  };
};
