// libraries.
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// slice.
import {
  getModePolygon,
  useGetPolygonsByMedianZoneQuery,
  ModePolygon,
  setModePolygon,
} from '@domains/save-polygons';
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';
import {
  getMedianZoneCode,
  setMedianZoneCode,
} from '@domains/median-zones/store/slices/medianZones.slice';
import { setListPolygons } from '@domains/save-polygons/store/slices/modePolygon.slice';
import { getRadiusByCountry } from '@domains/save-polygons/store/api-slices/utils';

export const EditPolygonButton = () => {
  const dispatch = useDispatch();
  const modePolygon = useSelector(getModePolygon);
  const country = useSelector(getCountry);
  const radius = getRadiusByCountry(country);
  const medianZoneCode = useSelector(getMedianZoneCode);

  const { data, refetch } = useGetPolygonsByMedianZoneQuery(
    {
      country: country,
      median_zone: medianZoneCode,
      radius: radius,
    },
    { skip: !medianZoneCode }
  );

  if (modePolygon !== ModePolygon.EDIT) {
    return null;
  }

  const handleClick = () => {
    dispatch(setModePolygon(ModePolygon.VIEW));
    dispatch(setMedianZoneCode(medianZoneCode));
    if (data) {
      dispatch(setListPolygons(data));
    }
    refetch();
  };

  return (
    <Button onClick={handleClick} className="btn-habi-white">
      Cancelar Edición
    </Button>
  );
};
