import { CountryCode } from '@domains/country';
import { apiAcquisitionsPortal } from '@store/apiSlices/apiSlices';

interface ParamsMedianZones {
  idMedianZone: string;
  country?: CountryCode;
  radius: number;
}

export interface MedianZonePolygon {
  id: number;
  polygon: Polygon[];
  label: string;
  polygon_status: number;
  origin_zone: number;
}

export interface Polygon {
  lat: number;
  lng: number;
}

interface ResponseMedianZones {
  body: MedianZonePolygon[];
  status_code: number;
  success: boolean;
}

export const medianZones = apiAcquisitionsPortal.injectEndpoints({
  endpoints: builder => ({
    getMedianZones: builder.query({
      query: ({ country, idMedianZone, radius }: ParamsMedianZones) => {
        return `/median_zone?country=${country}&id_median_zone=${idMedianZone}&radio=${radius}`;
      },
      transformResponse: (
        response: ResponseMedianZones
      ): MedianZonePolygon[] => {
        return response.body;
      },
      providesTags: ['MedianZones'],
    }),
  }),
});

export const { useGetMedianZonesQuery } = medianZones;
