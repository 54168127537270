// libraries.
import { styled } from 'styled-components';
import { useSelector } from 'react-redux';
import {
  electricPurple800,
  monserratSemiBold,
  robotoRegular,
  scarpaGray500,
} from '@habitech/shared';

// slice.
import { getIsDrawingPolygon } from '@domains/polygon-tool/store/slices/polygon-slice';
import { getModePolygon } from '@domains/save-polygons';
import { ModePolygon } from '@domains/save-polygons/store/slices/modePolygon.slice';
import { DropDownModePolygon } from '@domains/save-polygons/components/DropDownModePolygon/DropDownModePolygon';

const WrapperPolygonTitle = styled.div`
  font-family: ${monserratSemiBold};
  font-weight: 600;
  font-size: 20px;
  color: ${electricPurple800};
  .subtitle {
    font-family: ${robotoRegular};
    font-size: 12px;
    color: ${scarpaGray500};
    font-weight: 400;
  }
`;

export const PolygonTitleBar = () => {
  const isDrawing = useSelector(getIsDrawingPolygon);
  const modePolygon = useSelector(getModePolygon);

  let titleCopy = 'Polynator';
  if (isDrawing) {
    titleCopy = 'Crear nuevo polígono';
  } else if (modePolygon === ModePolygon.EDIT) {
    titleCopy = 'Editar polígonos';
  }

  return (
    <WrapperPolygonTitle className="d-flex flex-column align-items-center">
      <div className="d-flex w-100 gap-2 align-items-center">
        <div className="d-flex">{titleCopy}</div>
        <DropDownModePolygon />
      </div>
    </WrapperPolygonTitle>
  );
};
