// libraries.
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { electricPurple700, robotoRegular } from '@habitech/shared';

// selectors.
import { getIsHoveredSidebar } from '../store/slices/sidebar-slice';

export const Button = ({
  onClick,
  icon,
  text,
}: {
  onClick?: () => void;
  icon: JSX.Element;
  text: string;
}) => {
  const isHoveredSidebar = useSelector(getIsHoveredSidebar);
  const [isHoveredText, setIsHoveredText] = useState(false);

  const handleMouseEnter = () => {
    setIsHoveredText(true);
  };

  const handleMouseLeave = () => {
    setIsHoveredText(false);
  };

  return (
    <div
      className="d-flex align-items-center gap-2 btn p-1"
      onClick={onClick}
      style={{
        height: '40px',
        background: isHoveredText
          ? 'linear-gradient(90deg, #4A0199 0%, #9634FF 100%)'
          : 'transparent',
        borderColor: isHoveredText ? electricPurple700 : 'transparent',
      }}
    >
      <div className="d-flex align-items-center gap-1">
        <div style={{ width: '40px' }}>{icon}</div>
        {isHoveredSidebar && (
          <div
            className="fw-bold text-white text-nowrap pe-1"
            style={{
              fontFamily: robotoRegular,
              fontSize: '13px',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {text}
          </div>
        )}
      </div>
    </div>
  );
};
