// libraries.
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

// slices.
import {
  getIsModePolygonEdit,
  getListPolygons,
  setModePolygon,
  ModePolygon,
  setDefaultState,
  useSavePolygonsMutation,
  updateIntersectionListHandlerFromServerData,
  updateIntersectionHandlerListDirectly,
  getIntersectionListHandler,
} from '@domains/save-polygons';
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';
import { useGetSavedIntersectionsQuery } from '@domains/save-polygons/store/api-slices/savedIntersections.endpoints';
import { useCreateIntersectionsMutation } from '@domains/save-polygons/store/api-slices/createIntersections.endpoints';

export const UpdatePolygonButton = ({
  medianZoneCode,
}: {
  medianZoneCode: string;
}) => {
  const dispatch = useDispatch();
  const country = useSelector(getCountry);
  const isEditMode = useSelector(getIsModePolygonEdit);
  const polygons = useSelector(getListPolygons);

  const [savePolygonsEdited, { isSuccess, isError, isLoading }] =
    useSavePolygonsMutation();

  const { data } = useGetSavedIntersectionsQuery({
    country,
    median_zone_id: medianZoneCode,
  });

  const intersections = useSelector(getIntersectionListHandler);

  const [createIntersections, { isLoading: isLoadingCreateIntersections }] =
    useCreateIntersectionsMutation();

  const handleSavePolygonEdited = () => {
    const polygonsEdited = polygons.filter(polygon => polygon.edited);
    const polygonsToSave = polygonsEdited.map(polygon => {
      return {
        polygon_id: polygon.polygon_id + '',
        polygon: polygon.polygon,
        agent: polygon.created_by_agent,
        median_zone: medianZoneCode,
        active: polygon.active,
        comment: polygon.comment ?? '',
      };
    });
    savePolygonsEdited({
      country: country,
      items: polygonsToSave,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Polígonos actualizados correctamente');
      dispatch(setModePolygon(ModePolygon.VIEW));
      dispatch(setDefaultState());
      dispatch(updateIntersectionListHandlerFromServerData(data || []));
      dispatch(updateIntersectionHandlerListDirectly());

      const savePolygonList = intersections.map(intersection => {
        return {
          id: intersection.id,
          polygon_ids: intersection.idsPolygonString,
          intersection_geometry: intersection.intersection_geometry,
          priority_polygon_id: intersection.priorityPolygonId,
          comment: intersection.comment,
          status: intersection.status,
          agent: intersection.agent,
        };
      });
      createIntersections({
        country: country,
        median_zone_id: medianZoneCode,
        items: savePolygonList,
      });
    }
  }, [isSuccess, dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error('Ocurrió un error al actualizar los polígonos');
    }
  }, [isError]);

  useEffect(() => {
    if (isLoading) {
      toast.loading('Actualizando polígonos...', {
        toastId: 'loadingEditPolygon',
      });
    } else {
      toast.dismiss('loadingEditPolygon');
    }
  }, [isLoading]);

  useEffect(() => {
    if (isLoadingCreateIntersections) {
      toast.loading('Validando intersecciones...', {
        toastId: 'loadingCreateIntersections',
      });
    } else {
      toast.dismiss('loadingCreateIntersections');
    }
  }, [isLoadingCreateIntersections]);

  return (
    <>
      {isEditMode && (
        <Button className="btn-habi" onClick={handleSavePolygonEdited}>
          Guardar cambios
        </Button>
      )}
    </>
  );
};
