// assets.
import hero from '@assets/images/hero.png';

export interface ImgProps {
  className?: string;
  height?: number;
  style?: React.CSSProperties;
  width?: number;
}

export const ImageHero = ({ style, className, width, height }: ImgProps) => (
  <img
    src={hero}
    alt="hero"
    className={className}
    style={style}
    width={width}
    height={height}
  />
);
