// libraries.
import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';

// selectors.
import {
  getIntersectionListHandler,
  getPolygonsForCards,
} from '@domains/save-polygons';

// components.
import { InterceptionTitle } from './components/InterceptionTitle';
import { InterceptionBadge } from './components/InterceptionBadge';
import { InterceptionComment } from './components/InterceptionComment';
import { getIntersectionsByZone } from '@domains/save-polygons/store/slices/utils/intersectionsByZone';

export const CardsInterceptionPolygon = ({
  medianZoneId,
}: {
  medianZoneId: string;
}) => {
  const intersectionHandlerList = useSelector(getIntersectionListHandler);
  const listPolygons = useSelector(getPolygonsForCards);

  const intersectionsByZone = getIntersectionsByZone(
    intersectionHandlerList,
    listPolygons
  );

  return (
    <div className="d-flex flex-column gap-2 p-3">
      {intersectionsByZone.map((polygonInterception, index) => {
        return (
          <Card
            key={polygonInterception.idsPolygonString}
            className="d-flex p-3"
          >
            <div className="d-flex flex-column gap-2">
              <div className="d-flex justify-content-between gap-2 pb-1">
                <InterceptionTitle count={index + 1} />
                <div className="d-flex align-items-end gap-2">
                  {polygonInterception.polygonIds?.map(polygonId => {
                    const key = `badge-polygon-${polygonId}`;
                    return (
                      <InterceptionBadge
                        key={key}
                        id={polygonId}
                        idsPolygons={
                          polygonInterception.idsPolygonString as string
                        }
                        priorityPolygon={
                          polygonInterception.priorityPolygonId as string
                        }
                      />
                    );
                  })}
                </div>
              </div>
              <InterceptionComment
                idsPolygonString={
                  polygonInterception.idsPolygonString as string
                }
                comment={polygonInterception.comment}
              />
            </div>
          </Card>
        );
      })}
    </div>
  );
};
