// libraries.
import { ModelError } from '@store/apiSlices/interfaces/interfaces';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

// slices.
import { apiAcquisitionsPortal } from '@store/apiSlices/apiSlices';

// interfaces.
import {
  PolygonByNidParams,
  PolygonByNidResponse,
} from '@domains/median-zones/interfaces/interfaces';

export const searchMedianZones = apiAcquisitionsPortal.injectEndpoints({
  endpoints: builder => ({
    getSearchMedianZones: builder.query({
      query: ({ country, nid, lat, lng }: PolygonByNidParams) => {
        return `/search_median_zones?country=${country}&nid=${nid}&lat=${lat}&lng=${lng}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: 'getSearchMedianZones',
        };
        return modelError;
      },
      transformResponse: (response: PolygonByNidResponse) => {
        return response.body;
      },
    }),
  }),
});

export const { useGetSearchMedianZonesQuery } = searchMedianZones;
