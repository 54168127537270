// libraries.
import { Dropdown } from 'react-bootstrap';
import { robotoRegular } from '@habitech/shared';
import { useDispatch, useSelector } from 'react-redux';

// slices.
import {
  ModePolygon,
  getModePolygon,
  setModePolygon,
} from '@domains/save-polygons/store/slices/modePolygon.slice';
import { setDefaultState } from '@domains/polygon-tool/store/slices/polygon-slice';
import { polygonRefs } from '@domains/polygon-tool/utils';
import { getMedianZoneCode } from '@domains/median-zones/store/slices/medianZones.slice';

const modeOptions = [
  { mode: ModePolygon.CREATE, label: 'Crear Polígono' },
  { mode: ModePolygon.EDIT, label: 'Editar Polígono' },
  { mode: ModePolygon.VIEW, label: 'Ver Polígono' },
];

export const DropDownModePolygon = () => {
  const dispatch = useDispatch();
  const modePolygon = useSelector(getModePolygon);
  const medianZoneCode = useSelector(getMedianZoneCode);

  const handleSelect = (mode: ModePolygon) => {
    dispatch(setModePolygon(mode));
    polygonRefs.forEach(polygon => {
      if (polygon) {
        polygon.setMap(null);
      }
    });
    polygonRefs.length = 0;
    dispatch(setDefaultState());
  };

  const filteModeOptions = !medianZoneCode
    ? modeOptions.filter(option => option.mode === ModePolygon.VIEW)
    : modeOptions;

  return (
    <Dropdown>
      <Dropdown.Toggle className="btn-habi-white" id="dropdown-basic">
        {filteModeOptions.find(option => option.mode === modePolygon)?.label ||
          'Modo'}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {filteModeOptions.map(option => (
          <Dropdown.Item
            key={option.mode}
            onClick={() => handleSelect(option.mode)}
            style={{
              fontSize: '14px',
              fontFamily: robotoRegular,
            }}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
