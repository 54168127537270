// libraries.
import { Col, Row } from 'react-bootstrap';

// components.
import GoogleMap from '@domains/google-map/GoogleMap';
import { Layout } from '@shared';
import { ViewerTitle } from './components/ViewerTitle';
import { SideBarViewer } from './components/SideBarViewer/SideBarViewer';
import { PolygonsViewer } from '@domains/save-polygons/components/PolygonsViewer/PolygonsViewer';
import { CenterMedianZone } from '@pages/PolygonPage/modules/PolygonsDashboard/components/CenterMedianZone/CenterMedianZone';
import { LayoutPolygonViewer } from './components/LayoutPolygonViewer/LayoutPolygonViewer';
import { PolygonsInMedianZones } from '@domains/median-zones';

export const PolygonViewerDashboard = () => {
  return (
    <Layout>
      <SideBarViewer />
      <div className="h-100 w-100">
        <ViewerTitle />
        <Row
          className="m-0"
          style={{ height: 'calc(100% - 75px)', width: '100%' }}
        >
          <LayoutPolygonViewer />
          <Col className="p-0 bg-light">
            <GoogleMap
              containerStyle={{
                height: '100%',
                width: '100%',
              }}
            >
              <PolygonsInMedianZones />
              <CenterMedianZone />
              <PolygonsViewer />
            </GoogleMap>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};
