// libraries.
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// selectors.
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';
import { useSavePolygonsMutation } from '@domains/save-polygons/store/api-slices/savePolygons.endpoints';
import {
  Polygon,
  setDefaultState,
} from '@domains/polygon-tool/store/slices/polygon-slice';

// utils.
import { polygonRefs } from '../../../polygon-tool/utils';
import {
  ModePolygon,
  getModePolygon,
  setModePolygon,
} from '../../store/slices/modePolygon.slice';

interface SavePolygonParams {
  agent: string;
  median_zone: string;
  onSuccess: () => void;
  polygons: Polygon[];
}

export const CreatePolygonButton = ({
  agent,
  median_zone,
  polygons,
  onSuccess,
}: SavePolygonParams) => {
  const dispatch = useDispatch();
  const country = useSelector(getCountry);
  const modePolygon = useSelector(getModePolygon);

  const isCreateMode = modePolygon === ModePolygon.CREATE;
  const shouldShowButton = isCreateMode && polygons.length > 0;

  const [savePolygons, { isError, isLoading, isSuccess }] =
    useSavePolygonsMutation();

  const handleSavePolygon = () => {
    const savePolygonList = polygons.map(polygon => {
      return {
        polygon: polygon.polygon,
        agent,
        comment: polygon.comment,
        median_zone,
      };
    });
    savePolygons({
      country: country,
      items: savePolygonList,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Polígonos guardados correctamente');
      polygonRefs.forEach(polygon => {
        if (polygon) {
          polygon.setMap(null);
        }
      });
      polygonRefs.length = 0;
      dispatch(setModePolygon(ModePolygon.VIEW));
      dispatch(setDefaultState());
      onSuccess();
    }
    if (isError) {
      toast.error('Error creando los polígonos');
    }
  }, [isSuccess, isError]);

  return (
    <>
      {shouldShowButton && (
        <Button
          className="btn-habi"
          disabled={isLoading}
          onClick={handleSavePolygon}
        >
          {isLoading ? 'Guardando...' : 'Guardar cambios'}
        </Button>
      )}
    </>
  );
};
