// libraries.
import { useDispatch } from 'react-redux';

// components.
import { ButtonSidebar } from '@shared';

// assets.
import { IconMedianZones } from '@assets/icons';

// slices.
import { selectSidebarButton } from '@shared/Sidebar/store/slices/sidebar-slice';

// interfaces.
import { SidebarPolygonTool } from '../../../interfaces/sideBar.types';

export const ButtonMedianZone = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(selectSidebarButton(SidebarPolygonTool.medianZones));
  };

  return (
    <ButtonSidebar
      icon={<IconMedianZones />}
      text="Zonas Medianas"
      onClick={handleClick}
    />
  );
};
