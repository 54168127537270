// libraries.
import { useSelector } from 'react-redux';

// components.
import { IntersectionsList } from '@domains/intersections/components/IntersectionsList/IntersectionsList';
import { WrapperIntersections } from '@domains/intersections/components/IntersectionsList/styles';
import { CardsInterceptionPolygon } from '@domains/save-polygons';
import { SaveIntersections } from '@domains/save-polygons/components/SaveIntersections/SaveIntersections';

// selectors.
import { getMedianZoneCode } from '@domains/median-zones/store/slices/medianZones.slice';

export const IntersectionsTool = () => {
  const medianZoneId = useSelector(getMedianZoneCode);

  return (
    <div
      style={{
        height: 'calc(100vh - 75px)',
        overflowY: 'auto',
      }}
    >
      <WrapperIntersections>
        <div className="title p-3">Intersecciones</div>
        <IntersectionsList />
        <CardsInterceptionPolygon medianZoneId={medianZoneId} />
        <SaveIntersections medianZoneId={medianZoneId} />
      </WrapperIntersections>
    </div>
  );
};
