import { Tooltip } from 'react-bootstrap';

export const renderTooltip = (props: any, reasons: string[]) => {
  if (reasons.length === 0) {
    return <div />;
  }

  return (
    <Tooltip id="button-tooltip" data-testid="button-tooltip" {...props}>
      {reasons.map(reason => (
        <div key={reason}>{reason}</div>
      ))}
    </Tooltip>
  );
};
