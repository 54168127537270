// libraries.
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store';

export interface Polygon {
  comment?: string;
  polygon: google.maps.LatLngLiteral[];
  polygon_id?: string;
}
export interface PolygonToolState {
  isModalOpen: boolean;
  isDrawing: boolean;
  polygons: Polygon[];
}

export const initialState: PolygonToolState = {
  isModalOpen: false,
  isDrawing: false,
  polygons: [],
};

// slice.
export const domainPolygonToolSlice = createSlice({
  name: 'domainPolygonTool',
  initialState,
  reducers: {
    startDrawing: state => {
      state.isDrawing = true;
    },
    cancelDrawing: state => {
      state.isDrawing = false;
    },
    createPolygon: (state, action: PayloadAction<Polygon>) => {
      state.polygons.push(action.payload);
      state.isModalOpen = true;
    },
    deletePolygon: state => {
      state.polygons = [];
    },
    closeModal: state => {
      state.isModalOpen = false;
    },
    setComment: (
      state,
      action: PayloadAction<{ index: number; comment: string }>
    ) => {
      const { index, comment } = action.payload;
      if (state.polygons[index]) {
        state.polygons[index].comment = comment;
      }
    },
    setDefaultState: () => {
      return initialState;
    },
  },
});

export const {
  startDrawing,
  cancelDrawing,
  createPolygon,
  deletePolygon,
  closeModal,
  setComment,
  setDefaultState,
} = domainPolygonToolSlice.actions;

export default domainPolygonToolSlice.reducer;

// selectors.
export const getIsDrawingPolygon = (state: RootState) =>
  state.domainPolygonTool.isDrawing;

export const getPolygons = (state: RootState) =>
  state.domainPolygonTool.polygons;

export const getIsModalOpen = (state: RootState) =>
  state.domainPolygonTool.isModalOpen;
