// libraries.
import { styled } from 'styled-components';
import {
  electricPurple800,
  monserratSemiBold,
  robotoRegular,
  scarpaGray50,
  scarpaGray500,
} from '@habitech/shared';
import { Container } from 'react-bootstrap';

const WrapperPolygonTitle = styled.div`
  font-family: ${monserratSemiBold};
  font-weight: 600;
  font-size: 20px;
  color: ${electricPurple800};
  .subtitle {
    font-family: ${robotoRegular};
    font-size: 12px;
    color: ${scarpaGray500};
    font-weight: 400;
  }
`;

export const ViewerTitle = () => {
  return (
    <Container
      fluid
      className="p-4 pb-3 d-flex justify-content-between align-items-center"
      style={{ background: scarpaGray50 }}
    >
      <WrapperPolygonTitle className="d-flex flex-column align-items-center">
        <div className="d-flex w-100 mt-1 gap-2 align-items-center">
          <div className="d-flex">Aprobador de polígonos</div>
        </div>
      </WrapperPolygonTitle>
    </Container>
  );
};
