// Libraries.
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { scarpaGray50 } from '@habitech/shared';

// selectors.
import {
  getCountry,
  getEmail,
} from '@domains/user/store/slices/user-slice/user-slice';
import { getPolygons } from '@domains/polygon-tool/store/slices/polygon-slice';
import { getMedianZoneCode } from '@domains/median-zones/store/slices/medianZones.slice';
import { useGetPolygonsByMedianZoneQuery } from '@domains/save-polygons/store/api-slices/getPolygons.endpoints';

// components.
import { CreatePolygonButton } from '@domains/save-polygons/components/SaveButtons/CreatePolygonButton';
import { DeletePolygonButton } from '@domains/polygon-tool/components/DeletePolygonButton';
import { PolygonTitleBar } from './PolygonTitleBar';
import { PolygonButtons } from '@domains/polygon-tool/components/PolygonButtons/PolygonButtons';
import { UpdatePolygonButton } from '@domains/save-polygons/components/SaveButtons/UpdatePolygonButton';
import { PolygonCommentModal } from '@domains/polygon-tool/components/PolygonComment';
import { getRadiusByCountry } from '@domains/save-polygons/store/api-slices/utils';

export const PolygonActionsBar = () => {
  const agent = useSelector(getEmail);
  const country = useSelector(getCountry);
  const polygons = useSelector(getPolygons);
  const medianZoneCode = useSelector(getMedianZoneCode);
  const radius = getRadiusByCountry(country);

  const { refetch } = useGetPolygonsByMedianZoneQuery(
    {
      country: country,
      median_zone: medianZoneCode,
      radius: radius,
    },
    { skip: !medianZoneCode }
  );

  const onSuccess = () => {
    refetch();
  };

  return (
    <Container
      fluid
      className="p-4 pb-3 d-flex justify-content-between align-items-center"
      style={{ background: scarpaGray50 }}
    >
      <PolygonTitleBar />
      <div className="d-flex gap-2">
        <PolygonButtons />
        <CreatePolygonButton
          agent={agent}
          median_zone={medianZoneCode}
          polygons={polygons}
          onSuccess={onSuccess}
        />
        <PolygonCommentModal />
        <UpdatePolygonButton medianZoneCode={medianZoneCode} />
        <DeletePolygonButton />
      </div>
    </Container>
  );
};
