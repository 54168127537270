export interface SavedIntersectionsTransformed {
  id: number;
  polygonIds: string;
  agent: string;
  priorityPolygonId: string;
  intersection_geometry: google.maps.LatLngLiteral[];
  comment: string;
}

export const mockSavedIntersections = [
  {
    id: 1,
    polygonIds: ['1', '3'],
    agent: 'lauracanon@habi.co',
    priorityPolygonId: '3',
    intersection_geometry: [
      { lat: 20.7856789, lng: -103.4123456 },
      { lat: 20.7845678, lng: -103.4112345 },
      { lat: 20.7834567, lng: -103.4101234 },
      { lat: 20.7845678, lng: -103.4123456 },
    ],
    comment: 'Test',
  },
];

export const getRadiusByCountry = (country?: string) => {
  return country === 'MX' ? 1200 : 1300;
};
