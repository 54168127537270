// libraries.
import React from 'react';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// selectors.
import { getSelectedSidebarButton } from '@shared/Sidebar/store/slices/sidebar-slice';
import { SidebarViewer } from '../SideBarViewer/interfaces/sideBarViewer.types';

// components.
import { ViewerPolygons } from './components/ViewerPolygons/ViewerPolygons';
import { MedianZonesTool } from '@pages/PolygonPage/modules/PolygonsDashboard/components/LayoutPolygonTool/components/MedianZonesTool/MedianZonesTool';

const Layout = ({ children }: { children: React.ReactNode }) => (
  <Col lg={3} className="p-0">
    {children}
  </Col>
);

export const LayoutPolygonViewer = () => {
  const selectedViewerButton = useSelector(getSelectedSidebarButton);

  const isMedianZones =
    selectedViewerButton === SidebarViewer.viewerMedianZones;

  const isPolygonsInMedianZones =
    selectedViewerButton === SidebarViewer.viewerMedianZonePolygons;

  if (isMedianZones) {
    return (
      <Layout>
        <MedianZonesTool />
      </Layout>
    );
  }

  if (isPolygonsInMedianZones) {
    return (
      <Layout>
        <ViewerPolygons />
      </Layout>
    );
  }

  return null;
};
