import {
  scarpaGray300,
  scarpaGray800,
  skyBlue200,
  skyBlue700,
} from '@habitech/shared';

const optionsOriginPolygon = {
  fillColor: skyBlue200,
  fillOpacity: 0.4,
  strokeColor: skyBlue700,
  strokeOpacity: 0.5,
  strokeWeight: 1,
};

const optionsDefaultPolygon = {
  fillColor: scarpaGray300,
  fillOpacity: 0.4,
  strokeColor: scarpaGray800,
  strokeOpacity: 0.5,
  strokeWeight: 1,
};

export const optionsByOriginZone = (originZone: 0 | 1) => {
  if (originZone === 1) {
    return optionsOriginPolygon;
  }

  return optionsDefaultPolygon;
};
