// libraries.
import { useSelector, useDispatch } from 'react-redux';
import { electricPurple600, scarpaGray50 } from '@habitech/shared';
import { Col, Row } from 'react-bootstrap';
import { useEffect } from 'react';

// slices.
import { getIsDrawingPolygon } from '@domains/polygon-tool/store/slices/polygon-slice';

// components.
import GoogleMap from '@domains/google-map/GoogleMap';
import { EditorMode } from './components/EditorMode';
import { DrawingPolygon } from '@domains/polygon-tool/components/DrawingPolygon';
import { Layout } from '@shared';
import { PolygonActionsBar } from './components/PolygonActionsBar';
import { PolygonsInMedianZones } from '@domains/median-zones';
import { SidebarPolygons } from './components/SideBarPolygons/SideBarPolygons';
import { CenterMedianZone } from './components/CenterMedianZone/CenterMedianZone';
import { LayoutPolygonTool } from './components/LayoutPolygonTool/LayoutPolygonTool';
import {
  ListPolygons,
  updateIntersectionHandlerListDirectly,
  updateIntersectionListHandlerFromServerData,
} from '@domains/save-polygons';
import { PolygonsIntersection } from '@domains/save-polygons/components/CardsInterceptionPolygon/PolygonsInterception';

// selectors.
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';
import {
  getMedianZoneCode,
  getShowPolygons,
} from '@domains/median-zones/store/slices/medianZones.slice';

// hooks.
import { useGetSavedIntersectionsQuery } from '@domains/save-polygons/store/api-slices/savedIntersections.endpoints';

export const PolygonsDashboard = () => {
  const dispatch = useDispatch();
  const isDrawing = useSelector(getIsDrawingPolygon);

  const borderColor = isDrawing ? electricPurple600 : scarpaGray50;

  const country = useSelector(getCountry);
  const medianZoneId = useSelector(getMedianZoneCode);
  const showPolygons = useSelector(getShowPolygons);

  const { data } = useGetSavedIntersectionsQuery({
    country,
    median_zone_id: medianZoneId,
  });

  useEffect(() => {
    if (data) {
      dispatch(updateIntersectionListHandlerFromServerData(data));
      dispatch(updateIntersectionHandlerListDirectly());
    }
  }, [data, dispatch]);

  return (
    <Layout>
      <SidebarPolygons />
      <div className="h-100 w-100">
        <PolygonActionsBar />
        <Row
          className="m-0"
          style={{ height: 'calc(100% - 75px)', width: '100%' }}
        >
          <LayoutPolygonTool />
          <Col className="p-0">
            <GoogleMap
              containerStyle={{
                height: '100%',
                width: '100%',
                border: `3px solid ${borderColor}`,
              }}
              defaultZoom={17}
            >
              <PolygonsIntersection />
              <DrawingPolygon />
              <EditorMode />
              <PolygonsInMedianZones />
              <CenterMedianZone />
              <ListPolygons showPolygons={showPolygons} />
            </GoogleMap>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};
