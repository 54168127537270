// libraries.
import { useDispatch } from 'react-redux';

// components.
import {
  InputMedianZone,
  ListMedianZones,
  TurnPolygons,
} from '@domains/median-zones';
import { WrapperListMedianZone } from '@domains/median-zones/components/ListMedianZones/styles';
import { resetIntersectionListHandler } from '@domains/save-polygons/store/slices/modePolygon.slice';
import { SearchMedianZones } from '@domains/median-zones/components/SearchMedianZones/SearchMedianZones';

export const MedianZonesTool = () => {
  const dispatch = useDispatch();

  return (
    <div
      style={{
        height: 'calc(100vh - 75px)',
        overflowY: 'auto',
      }}
    >
      <WrapperListMedianZone>
        <SearchMedianZones />
        <InputMedianZone
          onChange={() => dispatch(resetIntersectionListHandler())}
        />
        <div className="p-3">
          <TurnPolygons />
        </div>
        <ListMedianZones />
      </WrapperListMedianZone>
    </div>
  );
};
