import { getMedianZoneCode } from '@domains/median-zones/store/slices/medianZones.slice';
import { getIntersectionListHandler, getListPolygons } from './state.selectors';
import { createSelector } from 'reselect';
import { getIntersectionsByZone } from '../utils/intersectionsByZone';

export const getPolygonsForCards = createSelector(
  [getListPolygons, getIntersectionListHandler, getMedianZoneCode],
  (listPolygons, intersectionListHandler, medianZoneCode) => {
    const intersectionSet = new Set<string>();

    intersectionListHandler.forEach(intersection => {
      intersection.polygonIds?.forEach(id => intersectionSet.add(id));
    });

    return listPolygons
      .filter(polygon => polygon.median_zone_id?.toString() === medianZoneCode)
      .map(polygon => ({
        ...polygon,
        showDeleteButton: !intersectionSet.has(polygon.polygon_id),
      }));
  }
);

export const getIfAgentCanSaveIntersections = createSelector(
  [getListPolygons, getIntersectionListHandler, getMedianZoneCode],
  (listPolygons, intersectionListHandler, medianZoneCode) => {
    const filteredPolygons = listPolygons.filter(
      polygon => polygon.median_zone_id?.toString() === medianZoneCode
    );

    const intersectionsByZone = getIntersectionsByZone(
      intersectionListHandler,
      filteredPolygons
    );

    let canSave = true;
    const reasonsSet = new Set<string>();

    intersectionsByZone.forEach(intersection => {
      if (!intersection.comment) {
        canSave = false;
        reasonsSet.add(
          `No tiene comentario la intersección ${intersection.idsPolygonString}`
        );
      }
      if (!intersection.priorityPolygonId) {
        canSave = false;
        reasonsSet.add(
          `No tiene polígono prioritario la intersección ${intersection.idsPolygonString}`
        );
      }
      if ((intersection.polygonIds?.length || 0) > 2) {
        canSave = false;
        reasonsSet.add(
          `Tiene más de dos polígonos la intersección ${intersection.idsPolygonString}`
        );
      }
    });

    return {
      canSave,
      reasons: Array.from(reasonsSet),
    };
  }
);
