// libraries.
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// selectors.
import { getIsModePolygonEdit } from '@domains/save-polygons';
import { updatePolygonCommentById } from '@domains/save-polygons/store/slices/modePolygon.slice';

export const EditPolygonComment = ({
  comment,
  polygonId,
}: {
  comment: string | undefined;
  polygonId: string;
}) => {
  const dispatch = useDispatch();
  const isEditing = useSelector(getIsModePolygonEdit);

  const handleCommentChange = (newComment: string) => {
    dispatch(updatePolygonCommentById({ polygonId, comment: newComment }));
  };

  return isEditing ? (
    <Form.Control
      as="textarea"
      rows={3}
      value={comment}
      placeholder="Ingresa un comentario"
      onChange={e => handleCommentChange(e.target.value)}
    />
  ) : (
    <p>{comment || 'No hay comentario'}</p>
  );
};
