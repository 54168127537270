import { IntersectionListHandler } from '@domains/save-polygons';
import { PolygonIntersection } from '@domains/turf/utils/findNIntersections';

export function updateIntersectionListHandler(
  intersectionsPolygon: PolygonIntersection[],
  currentIntersectionListHandler: IntersectionListHandler[]
): IntersectionListHandler[] {
  // Crear un mapa de idsPolygonString a handlers existentes
  const handlerMap = new Map<string, IntersectionListHandler>();
  currentIntersectionListHandler.forEach(handler => {
    if (handler.idsPolygonString) {
      handlerMap.set(handler.idsPolygonString, handler);
    }
  });

  const updatedHandlerList: IntersectionListHandler[] = [];

  // Procesar cada intersección del front
  intersectionsPolygon.forEach(intersection => {
    const idsPolygonString = intersection.idsPolygonString;
    const existingHandler = handlerMap.get(idsPolygonString);

    if (existingHandler) {
      if (existingHandler.id) {
        // Existe en backend y frontend
        existingHandler.polygonIds = intersection.polygon_ids;
        existingHandler.intersection_geometry = intersection.polygon;
        existingHandler.status = 'created';
        updatedHandlerList.push(existingHandler);
      } else {
        // Solo existe en frontend
        existingHandler.polygonIds = intersection.polygon_ids;
        existingHandler.intersection_geometry = intersection.polygon;
        existingHandler.status = 'create';
        updatedHandlerList.push(existingHandler);
      }
      handlerMap.delete(idsPolygonString); // Eliminar del mapa
    } else {
      // Crear nuevo handler con status 'create'
      const newHandler: IntersectionListHandler = {
        id: '',
        polygonIds: intersection.polygon_ids,
        idsPolygonString: idsPolygonString,
        intersection_geometry: intersection.polygon,
        priorityPolygonId: '',
        comment: '',
        status: 'create',
        agent: '',
      };
      updatedHandlerList.push(newHandler);
    }
  });

  // Procesar handlers que ya no existen en el frontend
  handlerMap.forEach(handler => {
    if (handler.id) {
      // Si el handler tiene un id, significa que existía en backend pero ya no en frontend
      handler.status = 'delete';
      updatedHandlerList.push(handler);
    }
    // Si el handler no tiene id, se elimina automáticamente al no agregarlo a updatedHandlerList
  });

  return updatedHandlerList;
}
