// libraries.
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiAuth = createApi({
  reducerPath: 'apiAuth',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.ENV_COGNITO_URL as string,
    prepareHeaders: (headers: Headers) => {
      headers.set('x-api-key', process.env.ENV_AUTH_API_KEY as string);
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: () => ({}),
});
