// interfaces.
interface EmbeddedBoardProps {
  height?: string;
  url: string;
  width?: string;
  className?: string;
  style?: React.CSSProperties;
}

/**
 *  This component allows embedding dashboards by receiving the dashboard URL as a prop.
 * @returns <EmbeddedBoard />
 */
export const EmbeddedBoard = ({
  url,
  className,
  style,
}: EmbeddedBoardProps) => {
  return (
    <iframe
      className={className}
      src={url}
      style={style}
      data-testid="release-notes"
    />
  );
};
