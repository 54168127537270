// libraries.
import { useDispatch } from 'react-redux';

// slice.
import { SidebarViewer } from '../interfaces/sideBarViewer.types';
import { selectSidebarButton } from '@shared/Sidebar/store/slices/sidebar-slice';

// components.
import { ButtonSidebar } from '@shared';

// assets.
import { IconMedianZones } from '@assets/icons';

export const ViewerMedianZoneButton = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(selectSidebarButton(SidebarViewer.viewerMedianZones));
  };

  return (
    <ButtonSidebar
      icon={<IconMedianZones />}
      text="Zonas Medianas"
      onClick={handleClick}
    />
  );
};
