// libraries.
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

// slices.
import { apiAcquisitionsPortal } from '@store/apiSlices/apiSlices';

// interfaces.
import { ModelError } from '@store/apiSlices/interfaces/interfaces';

import { Polygon, Feature, GeoJsonProperties } from 'geojson';

interface PolygonsParams {
  country?: string;
  median_zone: string;
  radius: number;
}

export interface PolygonObject {
  active?: string;
  color?: string;
  comment?: string;
  create_at?: string;
  created_by_agent: string;
  edited?: boolean;
  median_zone_id?: string;
  polygon: google.maps.LatLngLiteral[];
  polygon_id: string;
  select?: boolean;
  turf_polygon?: Feature<Polygon, GeoJsonProperties>;
  updated_by_agent?: string;
}

interface PolygonResponse {
  body: PolygonObject[];
  message: string;
  success: boolean;
}

export const polygonsByMedianZone = apiAcquisitionsPortal.injectEndpoints({
  endpoints: builder => ({
    getPolygonsByMedianZone: builder.query({
      query: ({ country, median_zone, radius }: PolygonsParams) => {
        return `/polygon?country=${country}&median_zone=${median_zone}&radio=${radius}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: 'getPolygonsByMedianZone',
        };
        return modelError;
      },
      transformResponse: (response: PolygonResponse): PolygonObject[] => {
        return response.body;
      },
      providesTags: ['Polygons'],
    }),
  }),
});

export const { useGetPolygonsByMedianZoneQuery } = polygonsByMedianZone;
