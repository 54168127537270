// libraries.
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// selectors.
import { getIntersectionCountsWithoutSave } from '@domains/save-polygons/store/slices/selectors/intersections.selectors';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

interface CounterIntersectionsWithoutSaveProps {
  onClickToast?: () => void;
}

const StyledCounterIntersectionsWithoutSave = styled.div`
  position: absolute;
  top: -10px;
  right: 0px;
  width: 24px;
  height: 24px;
`;

export const CounterIntersectionsWithoutSave = ({
  onClickToast,
}: CounterIntersectionsWithoutSaveProps) => {
  const { countWithoutId } = useSelector(getIntersectionCountsWithoutSave);

  useEffect(() => {
    if (countWithoutId > 0) {
      toast.info(`Intersecciones sin guardar: ${countWithoutId}`, {
        onClick: onClickToast,
        className: 'cursor-pointer',
      });
    }
  }, [countWithoutId]);

  if (countWithoutId === 0) return null;

  return (
    <StyledCounterIntersectionsWithoutSave className="bg-danger text-white fw-bold rounded-circle d-flex align-items-center justify-content-center">
      <small>{countWithoutId}</small>
    </StyledCounterIntersectionsWithoutSave>
  );
};
