// libraries.
import { useDispatch } from 'react-redux';

// slice.
import { SidebarViewer } from '../interfaces/sideBarViewer.types';
import { selectSidebarButton } from '@shared/Sidebar/store/slices/sidebar-slice';

// components.
import { ButtonSidebar } from '@shared';

// assets.
import { IconToolPolygon } from '@assets/icons';

export const ViewerPolygonsButton = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(selectSidebarButton(SidebarViewer.viewerMedianZonePolygons));
  };

  return (
    <ButtonSidebar
      icon={<IconToolPolygon />}
      text="Polígonos"
      onClick={handleClick}
    />
  );
};
