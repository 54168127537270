export const mapOptions: google.maps.MapOptions = {
  disableDefaultUI: false,
  disableDoubleClickZoom: false,
  draggable: true,
  fullscreenControl: true,
  keyboardShortcuts: true,
  mapTypeId: 'roadmap',
  rotateControl: true,
  scaleControl: false,
  scrollwheel: true,
  streetViewControl: true,
  zoomControl: true,
  //   styles: [
  //     {
  //       featureType: "all",
  //       elementType: "labels",
  //       stylers: [{ visibility: "on" }],
  //     },
  //     // ... más estilos aquí
  //   ],
};
