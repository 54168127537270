export enum CountryCode {
  MX = 'MX',
  CO = 'CO',
}

export interface ICountry {
  code: CountryCode;
  name: string;
}

export interface ICountrySelect {
  label: string;
  value: CountryCode;
}

export const countries: ICountry[] = [
  {
    code: CountryCode.MX,
    name: 'México',
  },
  {
    code: CountryCode.CO,
    name: 'Colombia',
  },
];

export const countryCodes: CountryCode[] = countries.map(
  country => country.code
);

export const getCountryByCode = (code: CountryCode): ICountry | undefined =>
  countries.find(country => country.code === code);

export const getCountryCodeFromRoles = (role: string[]): CountryCode[] => {
  return role.filter(role =>
    countryCodes.includes(role as CountryCode)
  ) as CountryCode[];
};

export const getCountryItemByCode = (
  code?: CountryCode
): ICountry | undefined => {
  return countries.find(country => country.code === code);
};

export const getCountryCodeByEmail = (
  email: string
): CountryCode | undefined => {
  const match = email.match(/\.\w+$/);
  const countryCode = match ? match[0].substring(1) : null;

  if (!countryCode) {
    throw new Error('Invalid email');
  }

  return countryCode.toUpperCase() as CountryCode;
};

export const selectForCountries = (
  countryCodes: CountryCode[]
): ICountrySelect[] => {
  return countries
    .filter(country => countryCodes.includes(country.code))
    .map(country => ({
      label: country.name,
      value: country.code,
    }));
};
