// libraries.
import { useSelector } from 'react-redux';

// selectors.
import { getListPolygons } from '@domains/save-polygons';
import { getMedianZoneCode } from '@domains/median-zones/store/slices/medianZones.slice';
import { getPolygonsInfo } from '@domains/median-zones/components/ListMedianZones/utils';

// utils.

export const TitlePolygons = () => {
  const listPolygons = useSelector(getListPolygons);
  const medianZoneCode = useSelector(getMedianZoneCode);
  const { lengthText } = getPolygonsInfo(listPolygons, medianZoneCode);

  return (
    <div className="p-3 title d-flex justify-content-between">
      <div>Polígonos</div>
      <div className="polygons">{lengthText}</div>
    </div>
  );
};
