import { robotoRegular, scarpaGray50 } from '@habitech/shared';
import { styled } from 'styled-components';

export const WrapperMedianZone = styled.div`
  font-size: 14px;
  font-family: ${robotoRegular};
  font-weight: 800;
  background: ${scarpaGray50};
  .form-control {
    font-size: 13px;
    font-family: ${robotoRegular};
  }
`;
