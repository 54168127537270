// libraries.
import { Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

// selectors.
import { getPolygonsForCards } from '@domains/save-polygons';

// hooks.
import {
  deselectPolygon,
  getIsHoveredPolygon,
  selectPolygonByPolygonId,
} from '@domains/save-polygons/store/slices/modePolygon.slice';

// components.
import { ButtonDeletePolygon } from './components/ButtonDeletePolygon';
import { PolygonComment } from './components/PolygonComment';

export const CardPolygons = () => {
  const dispatch = useDispatch();
  const listPolygons = useSelector(getPolygonsForCards);
  const hoveredPolygonId = useSelector(getIsHoveredPolygon);

  return (
    <div className="d-flex flex-column gap-2 p-3">
      {listPolygons.map(polygon => {
        const isHovered = polygon.polygon_id === hoveredPolygonId;
        const agent = polygon.updated_by_agent ?? polygon.created_by_agent;
        return (
          <Card
            key={polygon.polygon_id}
            onMouseEnter={() =>
              dispatch(selectPolygonByPolygonId(polygon.polygon_id))
            }
            onMouseLeave={() => dispatch(deselectPolygon())}
            className={`${isHovered ? 'hovered-card' : ''}`}
          >
            <Card.Body>
              <div className="id-polygon">ID: {polygon.polygon_id}</div>
              <div>{agent}</div>
              {polygon.showDeleteButton && (
                <ButtonDeletePolygon polygonId={polygon.polygon_id} />
              )}
            </Card.Body>
            <PolygonComment
              comment={polygon.comment}
              polygonId={polygon.polygon_id}
            />
          </Card>
        );
      })}
    </div>
  );
};
