// libraries.
import { useSelector } from 'react-redux';

// slice.
import { ModePolygon } from '@domains/save-polygons/store/slices/modePolygon.slice';
import { getModePolygon } from '@domains/save-polygons';

// components.
import { EditPolygonButton } from './EditPolygonButton';
import { DrawPolygonButton } from './DrawPolygonButton';

export const PolygonButtons = () => {
  const modePolygon = useSelector(getModePolygon);

  switch (modePolygon) {
    case ModePolygon.CREATE:
      return <DrawPolygonButton />;
    case ModePolygon.EDIT:
      return <EditPolygonButton />;
    default:
      return null;
  }
};
