// libraries.
import { useSelector, useDispatch } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';

// interfaces.
import { RootState } from '@store';
import { CountryCode } from '@domains/country';
import { setCountry } from '@domains/user/store/slices/user-slice/user-slice';
import { Country } from '../Country/Country';

// styles.
import { DropdownStyles } from './styles';

interface DropdownCountryProps {
  isHoveredSidebar: boolean;
}

export const DropdownCountry = ({ isHoveredSidebar }: DropdownCountryProps) => {
  const dispatch = useDispatch();
  const { countriesOptions, country } = useSelector(
    (state: RootState) => state.domainUserAuth
  );

  const handleChange = (code: CountryCode) => {
    dispatch(setCountry(code));
  };

  const countriesLen = countriesOptions?.length || 0;
  const defaultCountry = countriesOptions?.find(
    option => option.value === country
  );

  if (countriesLen === 0 || !defaultCountry) return <div>No hay países</div>;

  return (
    <Dropdown>
      <DropdownStyles className="d-flex align-items-center gap-2">
        <Dropdown.Toggle
          id="dropdown-basic"
          className={`d-flex align-items-center justify-content-center gap-2 ${isHoveredSidebar ? '' : 'hide-arrow'}`}
        >
          <Country code={defaultCountry.value} />
          {isHoveredSidebar && defaultCountry.label}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {countriesOptions!.map(countryOption => (
            <Dropdown.Item
              key={countryOption.value}
              onClick={() => handleChange(countryOption.value)}
              className="d-flex align-items-center gap-2"
            >
              <Country code={countryOption.value} />{' '}
              <p>{countryOption.label}</p>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </DropdownStyles>
    </Dropdown>
  );
};
