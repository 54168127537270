// libraries.
import { Form } from 'react-bootstrap';

interface CoordinatesInputsProps {
  lat: string;
  lng: string;
  onLatChange: (value: string) => void;
  onLngChange: (value: string) => void;
}

export const CoordinatesInputs = ({
  lat,
  lng,
  onLatChange,
  onLngChange,
}: CoordinatesInputsProps) => {
  const handleLatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onLatChange(e.target.value);
  };

  const handleLngChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onLngChange(e.target.value);
  };

  return (
    <>
      <Form.Control
        placeholder="Latitud"
        value={lat}
        onChange={handleLatChange}
      />
      <Form.Control
        placeholder="Longitud"
        value={lng}
        onChange={handleLngChange}
      />
    </>
  );
};
