// components.
import { ButtonLogo, Sidebar } from '@shared';
import { ViewerMedianZoneButton } from './components/ViewerMedianZoneButton';
import { ViewerPolygonsButton } from './components/ViewerPolygonsButton';

export const SideBarViewer = () => {
  return (
    <Sidebar
      upperButtons={
        <>
          <ButtonLogo />
          <ViewerMedianZoneButton />
          <ViewerPolygonsButton />
        </>
      }
    />
  );
};
