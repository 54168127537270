// libraries.
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

// slices.
import { apiAcquisitionsPortal } from '@store/apiSlices/apiSlices';

// interfaces.
import { ModelError } from '@store/apiSlices/interfaces/interfaces';

export interface IntersectionsModel {
  id?: string;
  polygon_ids?: string;
  intersection_geometry?: google.maps.LatLngLiteral[];
  priority_polygon_id?: string;
  comment?: string;
  status?: string;
  agent: string;
}
export interface CreateIntersectionsModel {
  country?: string;
  median_zone_id?: string;
  items: IntersectionsModel[];
}

interface CreateIntersectionsResponse {
  message: string;
  success: boolean;
}

export const createIntersections = apiAcquisitionsPortal.injectEndpoints({
  endpoints: builder => ({
    createIntersections: builder.mutation({
      query: (newIntersection: CreateIntersectionsModel) => ({
        body: newIntersection,
        method: 'PUT',
        url: '/intersection',
      }),
      invalidatesTags: ['getSavedIntersections'],
      transformErrorResponse: (error: FetchBaseQueryError): ModelError => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: 'createIntersections',
        };
        return modelError;
      },
      transformResponse: (
        response: CreateIntersectionsResponse
      ): CreateIntersectionsResponse => {
        return response;
      },
    }),
  }),
});

export const { useCreateIntersectionsMutation } = createIntersections;
