// libraries.
import { ReactNode } from 'react';

export const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="h-100">
      <div className="h-100 d-flex">{children}</div>
    </div>
  );
};
