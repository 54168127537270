// libraries.
import { styled } from 'styled-components';
import { robotoRegular, scarpaGray50 } from '@habitech/shared';

export const WrapperIntersections = styled.div`
  font-family: ${robotoRegular};
  font-size: 14px;
  .title {
    font-weight: 600;
    background: ${scarpaGray50};
  }
`;
