// libraries.
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

// slices.
import { apiAcquisitionsPortal } from '@store/apiSlices/apiSlices';

// interfaces.
import { ModelError } from '@store/apiSlices/interfaces/interfaces';
import { IntersectionListHandler } from '@domains/save-polygons/interfaces';

interface SavedIntersectionsParams {
  country?: string;
  median_zone_id: string;
}

export interface SavedIntersections {
  intersection_id: string;
  list_polygons: string;
  created_agent: string;
  main_polygon: string;
  intersection_geometry: google.maps.LatLngLiteral[];
  user_comment: string;
}

interface SavedIntersectionsResponse {
  body: SavedIntersections[];
  status_code: number;
  success: boolean;
}

export const savedIntersections = apiAcquisitionsPortal.injectEndpoints({
  endpoints: builder => ({
    getSavedIntersections: builder.query({
      query: ({ country, median_zone_id }: SavedIntersectionsParams) => {
        return `/stored_intersections?country=${country}&median_zone=${median_zone_id}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: 'getSavedIntersections',
        };
        return modelError;
      },
      transformResponse: (
        response: SavedIntersectionsResponse
      ): IntersectionListHandler[] => {
        return response.body.map(item => ({
          id: item.intersection_id,
          idsPolygonString: item.list_polygons,
          polygonIds: item.list_polygons.split(','),
          agent: item.created_agent,
          priorityPolygonId: item.main_polygon,
          intersection_geometry: item.intersection_geometry,
          comment: item.user_comment,
          status: 'created',
        }));
      },
      providesTags: ['getSavedIntersections'],
    }),
  }),
});

export const { useGetSavedIntersectionsQuery } = savedIntersections;
