// Libraries.
import styled from 'styled-components';
import {
  tomatoCoral500,
  electricPurple600,
  white,
  robotoRegular,
} from '@habitech/shared';

export const ErrorLogin = styled.div`
  color: ${tomatoCoral500};
  margin-top: 8px;
`;

export const ButtonGoogleHabi = styled.div`
  background: ${electricPurple600};
  color: ${white};
  font-size: 16px;
  width: 347px;
  line-height: 19px;
  border-radius: 100px;
  padding: 15px 54px;
  cursor: pointer;
  width: 347px;
  .ingresar {
    text-align: center;
    color: ${white};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: ${robotoRegular};
  }

  img {
    margin: 0;
    margin-right: 8px;
    border-radius: 100px;
  }
`;
