// libraries.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Intersection } from '../api-slices/intersections.endpoints';

// interfaces.
export interface StateIntersections {
  listIntersections: Intersection[] | [];
}

export const initialState: StateIntersections = {
  listIntersections: [],
};

export const domainIntersectionsSlice = createSlice({
  name: 'domainIntersections',
  initialState,
  reducers: {
    setListIntersections: (
      state,
      action: PayloadAction<Intersection[] | []>
    ) => {
      state.listIntersections = action.payload;
    },
    setDefaultState: () => {
      return initialState;
    },
  },
});

export const { setListIntersections, setDefaultState } =
  domainIntersectionsSlice.actions;

export default domainIntersectionsSlice.reducer;
