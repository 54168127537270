// libraries.
import { Form } from 'react-bootstrap';

interface NidInputProps {
  value: string;
  onChange: (value: string) => void;
}

export const NidInput = ({ value, onChange }: NidInputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <Form.Control placeholder="NID" value={value} onChange={handleChange} />
  );
};
