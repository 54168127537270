import {
  blueLinks,
  robotoRegular,
  scarpaGray500,
  skyBlue25,
} from '@habitech/shared';
import { styled } from 'styled-components';

export const WrapperListMedianZone = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${robotoRegular};
  font-size: 13px;
  font-weight: 700;
  .active {
    background: ${skyBlue25};
    color: ${blueLinks};
  }
  .polygons {
    color: ${scarpaGray500};
    font-weight: 400;
  }
`;
