import { electricPurple700, robotoRegular } from '@habitech/shared';
import { styled } from 'styled-components';

export const DropdownStyles = styled.div`
  font-family: ${robotoRegular};
  .hide-arrow::after {
    display: none !important;
  }
  p {
    font-family: ${robotoRegular};
  }
  button {
    font-size: 14px;
    height: 40px;
    background: linear-gradient(90deg, #4a0199 0%, #9634ff 100%);
    border-color: ${electricPurple700};
    &:hover {
      border-color: ${electricPurple700};
    }
  }
`;
