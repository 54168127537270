import { electricPurple700, scarpaGray50 } from '@habitech/shared';
import { styled } from 'styled-components';

export const WrapperComment = styled.div`
  font-size: 14px;
  .accordion-body {
    padding: 14px;
    background-color: ${scarpaGray50};
  }
  .accordion-button {
    background-color: ${scarpaGray50};
    padding: 8px 16px;
    font-size: 14px;
    text-decoration: underline;
    color: ${electricPurple700};
    display: flex;
    justify-content: flex-end;
    gap: 4px;
  }
  .accordion-button::after {
    margin-left: 0;
  }
  .accordion-button:not(.collapsed) {
    background-color: ${scarpaGray50};
  }
  .accordion-button:focus {
    box-shadow: none;
  }
  .form-control {
    font-size: 14px;
    padding: 5px;
  }
`;
