// interface.
import { IRoute } from '@routes/routes';

// types.
import { pathPolygonViewer } from './routes.types';

// components.
import { PolygonViewerDashboard } from '../modules/PolygonViewerDashboard/PolygonViewerDashboard';

export const routesPolygonViewerPages: IRoute[] = [
  {
    id: 'dashboard-polygon-viewer',
    path: `${pathPolygonViewer}`,
    element: <PolygonViewerDashboard />,
  },
];
