import { Feature, Polygon, GeoJsonProperties } from 'geojson';
import { booleanIntersects, featureCollection, intersect } from '@turf/turf';

// Interfaces.
import { PolygonObject } from '@domains/save-polygons/store/api-slices/getPolygons.endpoints';
import { GeoJSONToLatLngArray } from './latLngArrayToGeoJSON';

export interface PolygonIntersection {
  polygon_ids: string[];
  idsPolygonString: string;
  turf_polygon_intersection?: Feature<Polygon, GeoJsonProperties>;
  polygon: google.maps.LatLngLiteral[];
}

/**
 * Construye un grafo de intersección entre polígonos.
 */
export function buildIntersectionGraph(
  polygons: PolygonObject[]
): Map<string, Set<string>> {
  const graph = new Map<string, Set<string>>();

  // Inicializar todos los nodos en el grafo
  for (const poly of polygons) {
    if (!poly.turf_polygon) continue;
    const id = poly.polygon_id;
    graph.set(id, new Set());
  }

  // Procesar intersecciones
  for (let i = 0; i < polygons.length; i++) {
    processIntersections(polygons, i, graph);
  }

  return graph;
}

function processIntersections(
  polygons: PolygonObject[],
  index: number,
  graph: Map<string, Set<string>>
): void {
  const poly1 = polygons[index];
  if (!poly1.turf_polygon) return;
  const id1 = poly1.polygon_id;

  for (let j = index + 1; j < polygons.length; j++) {
    const poly2 = polygons[j];
    if (!poly2.turf_polygon) continue;
    const id2 = poly2.polygon_id;

    if (booleanIntersects(poly1.turf_polygon, poly2.turf_polygon)) {
      graph.get(id1)!.add(id2);
      graph.get(id2)!.add(id1);
    }
  }
}

/**
 * Genera todas las combinaciones posibles de polígonos que se intersectan mutuamente dentro de un componente conectado.
 */
function generateIntersectingCombinations(
  polygonsMap: Map<string, PolygonObject>,
  component: string[]
): PolygonIntersection[] {
  const results: PolygonIntersection[] = [];
  const n = component.length;

  // Generar todas las combinaciones posibles de tamaño >= 2
  for (let r = 2; r <= n; r++) {
    const combinations = k_combinations(component, r);

    for (const combo of combinations) {
      const intersection = calculateGroupIntersection(polygonsMap, combo);
      if (intersection) {
        const polygon = GeoJSONToLatLngArray(intersection);
        const idsString = combo.join(',');
        results.push({
          polygon_ids: combo,
          turf_polygon_intersection: intersection,
          idsPolygonString: idsString,
          polygon,
        });
      }
    }
  }

  return results;
}

/**
 * Calcula todas las combinaciones de tamaño k de un array.
 */
function k_combinations(set: string[], k: number): string[][] {
  let i, j, combs, head, tailcombs;

  if (k > set.length || k <= 0) {
    return [];
  }

  if (k === set.length) {
    return [set];
  }

  if (k === 1) {
    combs = [];
    for (i = 0; i < set.length; i++) {
      combs.push([set[i]]);
    }
    return combs;
  }

  combs = [];
  for (i = 0; i < set.length - k + 1; i++) {
    head = set.slice(i, i + 1);
    tailcombs = k_combinations(set.slice(i + 1), k - 1);
    for (j = 0; j < tailcombs.length; j++) {
      combs.push(head.concat(tailcombs[j]));
    }
  }
  return combs;
}

/**
 * Calcula la intersección conjunta de un grupo de polígonos.
 */
function calculateGroupIntersection(
  polygonsMap: Map<string, PolygonObject>,
  ids: string[]
): Feature<Polygon, GeoJsonProperties> | undefined {
  if (ids.length === 0) return undefined;

  let intersection = polygonsMap.get(ids[0])?.turf_polygon;
  if (!intersection) return undefined;

  for (let i = 1; i < ids.length; i++) {
    const poly = polygonsMap.get(ids[i])?.turf_polygon;
    if (!poly) continue;
    const aux = featureCollection([intersection!, poly]);
    const intersectionResult = intersect(aux);
    if (!intersectionResult) {
      return undefined;
    }
    const newIntersection = intersectionResult as Feature<
      Polygon,
      GeoJsonProperties
    >;
    intersection = newIntersection;
  }

  return intersection as Feature<Polygon, GeoJsonProperties>;
}

/**
 * Encuentra todas las intersecciones posibles entre un array de polígonos.
 */
export function findAllIntersections(
  polygons: PolygonObject[]
): PolygonIntersection[] {
  if (polygons.length < 2) {
    return [];
  }

  // Construir un mapa de polígonos por ID para acceso rápido
  const polygonsMap = new Map<string, PolygonObject>();
  for (const poly of polygons) {
    polygonsMap.set(poly.polygon_id, poly);
  }

  // Construir el grafo de intersección
  const graph = buildIntersectionGraph(polygons);

  // Encontrar los componentes conectados
  const connectedComponents = findConnectedComponents(graph);

  const intersections: PolygonIntersection[] = [];

  for (const component of connectedComponents) {
    // Generar todas las combinaciones de intersecciones dentro del componente
    const componentIntersections = generateIntersectingCombinations(
      polygonsMap,
      component
    );
    intersections.push(...componentIntersections);
  }

  return intersections;
}

/**
 * Encuentra los componentes conectados en el grafo de intersección.
 */
export function findConnectedComponents(
  graph: Map<string, Set<string>>
): string[][] {
  const visited = new Set<string>();
  const components: string[][] = [];

  for (const node of graph.keys()) {
    if (!visited.has(node)) {
      const component: string[] = [];
      dfs(node, graph, visited, component);
      components.push(component);
    }
  }

  return components;
}

/**
 * Realiza una búsqueda en profundidad para encontrar componentes conectados.
 */
function dfs(
  node: string,
  graph: Map<string, Set<string>>,
  visited: Set<string>,
  component: string[]
) {
  visited.add(node);
  component.push(node);

  for (const neighbor of graph.get(node) || []) {
    if (!visited.has(neighbor)) {
      dfs(neighbor, graph, visited, component);
    }
  }
}
