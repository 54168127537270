// libraries.
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// slices.
import {
  getShowPolygons,
  setShowPolygons,
} from '@domains/median-zones/store/slices/medianZones.slice';

export const TurnPolygons = () => {
  const dispatch = useDispatch();

  const showPolygons = useSelector(getShowPolygons);

  const handleShowPolygons = () => {
    dispatch(setShowPolygons(!showPolygons));
  };

  return (
    <Form.Check
      type="switch"
      id="switch-polygons"
      label="Polígonos"
      onChange={handleShowPolygons}
      checked={showPolygons}
    />
  );
};
