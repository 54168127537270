// libraries.
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// hooks.
import { useGetMedianZonesQuery } from '@domains/median-zones/store/api-slices/medianZones.endpoint';

// selectors.
import { getMedianZoneCode } from '@domains/median-zones/store/slices/medianZones.slice';
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';

export const CenterMedianZone = ({ mapRef }: { mapRef: any }) => {
  const medianZoneCode = useSelector(getMedianZoneCode);
  const country = useSelector(getCountry);

  const { data: medianZonesPolygon } = useGetMedianZonesQuery(
    {
      country,
      idMedianZone: medianZoneCode,
      radius: 2000,
    },
    { skip: !medianZoneCode }
  );

  const polygonOrigin = medianZonesPolygon?.find(
    polygon => polygon.origin_zone === 1
  );

  useEffect(() => {
    const polygonLen = polygonOrigin?.polygon.length;
    if (mapRef && polygonLen) {
      const bounds = new window.google.maps.LatLngBounds();
      console.log('polygonOrigin', polygonOrigin);
      polygonOrigin.polygon.forEach(coord => {
        bounds.extend(coord);
      });

      mapRef.fitBounds(bounds);
    }
  }, [polygonOrigin?.polygon]);

  return null;
};
