// libraries.
import styled from 'styled-components';
import {
  monserratSemiBold,
  robotoRegular,
  scarpaGray500,
} from '@habitech/shared';

export const WrapperLogin = styled.div`
  .google {
    font-family: ${monserratSemiBold};
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    line-height: 32px;
  }
  .title {
    font-family: ${robotoRegular};
    font-size: 14px;
    font-weight: 400;
    color: ${scarpaGray500};
  }
`;
