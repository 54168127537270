// libraries.
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

// slices.
import { apiAcquisitionsPortal } from '@store/apiSlices/apiSlices';

// interfaces.
import { ModelError } from '@store/apiSlices/interfaces/interfaces';

interface RolesPolynatorParams {
  country: string;
  email: string;
}

interface RolesPolynatorResponse {
  status_code: 200;
  success: true;
  body: {
    role: 'PolygonCreator';
  };
}

export enum RolesPolynatorEnum {
  PolygonCreator = 'PolygonCreator',
  PolygonViewer = 'PolygonViewer',
}

interface RolesPolynator {
  role: RolesPolynatorEnum;
}

export const rolesPolynatorApi = apiAcquisitionsPortal.injectEndpoints({
  endpoints: builder => ({
    getRolesPolynator: builder.query<RolesPolynator, RolesPolynatorParams>({
      query: ({ country, email }) => {
        return `/roles_polynator?country=${country}&email=${email}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any).message,
          serviceErrorName: 'getRolesPolynator',
        };
        return modelError;
      },
      transformResponse: (response: RolesPolynatorResponse): RolesPolynator => {
        const role = response.body.role as RolesPolynatorEnum;
        return {
          role,
        };
      },
    }),
  }),
});

export const { useGetRolesPolynatorQuery } = rolesPolynatorApi;
