// libraries.
import { useDispatch } from 'react-redux';

// components.
import { ButtonSidebar } from '@shared';
import { CounterIntersectionsWithoutSave } from '@domains/save-polygons';

// assets.
import { IconIntersections } from '@assets/icons';

// slices.
import { selectSidebarButton } from '@shared/Sidebar/store/slices/sidebar-slice';

// interfaces.
import { SidebarPolygonTool } from '../../../interfaces/sideBar.types';

export const ButtonIntersections = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(selectSidebarButton(SidebarPolygonTool.polygonsIntersections));
  };

  return (
    <div className="position-relative">
      <CounterIntersectionsWithoutSave onClickToast={handleClick} />
      <ButtonSidebar
        icon={<IconIntersections />}
        text="Intersecciones"
        onClick={handleClick}
      />
    </div>
  );
};
