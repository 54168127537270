import { PolygonObject } from '@domains/save-polygons/store/api-slices/getPolygons.endpoints';

export const getPolygonsInfo = (
  listPolygons: PolygonObject[],
  medianZoneCode?: string
): { lengthText: string | undefined; polygons: PolygonObject[] } => {
  const polygons = listPolygons.filter(
    polygon => polygon.median_zone_id?.toString() === medianZoneCode
  );

  return {
    lengthText:
      polygons.length > 0 ? `${polygons.length} polígonos` : undefined,
    polygons: polygons,
  };
};
