// libraries.
import { useSelector } from 'react-redux';

// slices.
import { getIsHoveredSidebar } from '@shared/Sidebar/store/slices/sidebar-slice';
import { getIsRoleIsPolygonCreator } from '@domains/user/store/slices/user-slice/user-slice';

// components.
import { ButtonLogo, Sidebar } from '@shared';
import { ButtonToolPolygon } from './components/ButtonToolPolygon';
import { DropdownCountry } from '@domains/user/components/DropdownCountry/DropdownCountry';
import { LogOutButton } from '@domains/user/components/LogOutButton/LogOutButton';
import { ButtonPolygonViewer } from './components/ButtonPolygonViewer';

export const SidebarHome = () => {
  const isHoveredSidebar = useSelector(getIsHoveredSidebar);
  const agentIsPolygonCreator = useSelector(getIsRoleIsPolygonCreator);

  return (
    <Sidebar
      upperButtons={
        <>
          <ButtonLogo />
          {agentIsPolygonCreator && <ButtonToolPolygon />}
          {!agentIsPolygonCreator && <ButtonPolygonViewer />}
        </>
      }
      lowerButtons={
        <>
          <DropdownCountry isHoveredSidebar={isHoveredSidebar} />
          <LogOutButton />
        </>
      }
    />
  );
};
