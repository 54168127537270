// libraries.
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { useEffect } from 'react';
import { ButtonGoogleHabi } from './styles';
import { useDispatch } from 'react-redux';

// slices.
import { setUser } from '@domains/user';
import { CountryCode } from '@domains/country';
import { useAuthMutation } from '@domains/user/store/api-slices/user-auth.endpoint';
import {
  setCountriesOptions,
  setCountry,
  setRole,
} from '@domains/user/store/slices/user-slice/user-slice';

interface PropsButtonGoogle {
  onSuccess?: () => void;
  onFailure?: () => void;
}

export const ButtonGoogle = ({ onSuccess, onFailure }: PropsButtonGoogle) => {
  const dispatch = useDispatch();
  const [mutateAuth, { data, isSuccess, isLoading }] = useAuthMutation();
  const authKey = process.env.ENV_GOOGLE_AUTH;

  const successLoginGoogle = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if ('profileObj' in response) {
      dispatch(
        setUser({
          email: response.profileObj.email,
          familyName: response.profileObj.familyName,
          givenName: response.profileObj.givenName,
          googleId: response.profileObj.googleId,
          imageUrl: response.profileObj.imageUrl,
          name: response.profileObj.name,
        })
      );
      mutateAuth({ userId: response.profileObj.googleId });
    }
  };

  const failLoginGoogle = (response: any) => {
    onFailure && onFailure();
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setRole(data?.roles[0] || ''));
      dispatch(setCountriesOptions(data?.countriesSelect || []));
      dispatch(setCountry(data?.countriesByUser[0] || ('' as CountryCode)));
      onSuccess && onSuccess();
    }
  }, [isSuccess, data, dispatch]);

  if (!authKey) {
    return (
      <div className="text-danger">
        No se ha encontrado la clave de autenticación
      </div>
    );
  }

  if (isLoading) {
    return (
      <ButtonGoogleHabi>
        <p className="ingresar">Cargando...</p>
      </ButtonGoogleHabi>
    );
  }

  return (
    <GoogleLogin
      clientId={authKey}
      buttonText="Inciar sesión"
      onSuccess={successLoginGoogle}
      onFailure={failLoginGoogle}
      cookiePolicy="single_host_origin"
      render={renderProps => (
        <ButtonGoogleHabi onClick={renderProps.onClick}>
          <p className="ingresar">Ingresar</p>
        </ButtonGoogleHabi>
      )}
    />
  );
};
