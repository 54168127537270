// libraries.
import { PolygonF } from '@react-google-maps/api';
import { maximumYellow400, spanishViridian500 } from '@habitech/shared';
import { useSelector } from 'react-redux';

// selectors.
import {
  getIntersectionListHandler,
  getIntersectionsPolygon,
} from '@domains/save-polygons/store/slices/modePolygon.slice';

export const PolygonsIntersection = () => {
  const intersectionsListHandler = useSelector(getIntersectionListHandler);
  const polygonsInterception = useSelector(getIntersectionsPolygon);

  return (
    <>
      {polygonsInterception.map(polygonInterception => {
        const interceptionId = polygonInterception.idsPolygonString;
        const hasId = intersectionsListHandler.find(
          intersection =>
            intersection.idsPolygonString?.includes(interceptionId) &&
            intersection.id
        );
        return (
          <PolygonF
            key={interceptionId}
            path={polygonInterception.polygon}
            options={{
              fillColor: hasId ? spanishViridian500 : maximumYellow400,
              fillOpacity: 0.5,
              strokeColor: hasId ? spanishViridian500 : maximumYellow400,
              strokeOpacity: 0.8,
              strokeWeight: 1,
              zIndex: 200,
            }}
          />
        );
      })}
    </>
  );
};
