// libraries.
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// selector.
import { getEmail } from '@domains/user/store/slices/user-slice/user-slice';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const email = useSelector(getEmail);

  if (!email) {
    return <Navigate to="/" replace />;
  }

  return children;
};
