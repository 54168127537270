// assets.
import HabiPricingIcon from './habi-pricing.svg';
import DeleteIcon from './delete-icon.svg';
import EditIcon from './edit-icon.svg';
import HabiIcon from './habi-icon.svg';
import ToolPolygonIcon from './tool-polygon-icon.svg';
import MedianZonesIcon from './median-zones.svg';
import IntersectionsIcon from './intersections.svg';
import LogOutIcon from './log-out.svg';
interface IconProps {
  fill?: string;
  height?: string;
  width?: string;
}

export const IconHabiPricing = ({
  width = '185',
  height = '80',
  fill,
}: IconProps) => {
  return (
    <HabiPricingIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconDelete = ({
  width = '20',
  height = '20',
  fill,
}: IconProps) => {
  return <DeleteIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconEdit = ({ width = '16', height = '16', fill }: IconProps) => {
  return <EditIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconHabi = ({ width = '24', height = '24', fill }: IconProps) => {
  return <HabiIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconToolPolygon = ({
  width = '24',
  height = '24',
  fill,
}: IconProps) => {
  return (
    <ToolPolygonIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconMedianZones = ({
  width = '24',
  height = '24',
  fill,
}: IconProps) => {
  return (
    <MedianZonesIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconIntersections = ({
  width = '20',
  height = '20',
  fill,
}: IconProps) => {
  return (
    <IntersectionsIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconLogOut = ({
  width = '24',
  height = '24',
  fill,
}: IconProps) => {
  return <LogOutIcon style={{ color: fill }} width={width} height={height} />;
};
