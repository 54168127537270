// libraries.
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

// slice.
import {
  cancelDrawing,
  getIsDrawingPolygon,
  startDrawing,
} from '../../store/slices/polygon-slice';
import {
  ModePolygon,
  getModePolygon,
} from '@domains/save-polygons/store/slices/modePolygon.slice';

export const DrawPolygonButton = () => {
  const dispatch = useDispatch();
  const isDrawing = useSelector(getIsDrawingPolygon);
  const modePolygon = useSelector(getModePolygon);

  if (modePolygon !== ModePolygon.CREATE) {
    return null;
  }

  const handleClick = () => {
    if (isDrawing) {
      dispatch(cancelDrawing());
    } else {
      dispatch(startDrawing());
    }
  };

  const buttonType = isDrawing ? 'btn-habi-white' : 'btn-habi';

  return (
    <Button onClick={handleClick} className={buttonType}>
      {isDrawing ? 'Cancelar Trazado' : 'Nuevo Polígono'}
    </Button>
  );
};
