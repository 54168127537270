// libraries.
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// selectors.
import { getListPolygons } from '@domains/save-polygons';

export const PolygonCardsViewer = () => {
  const listPolygons = useSelector(getListPolygons);

  return (
    <div className="d-flex flex-column gap-2 p-3">
      {listPolygons.map(polygon => {
        return (
          <Card key={polygon.polygon_id}>
            <Card.Body>
              <div className="id">ID: {polygon.polygon_id}</div>
              <div>{polygon.created_by_agent}</div>
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
};
