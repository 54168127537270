// libraries.
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store';

// interfaces.
export interface SidebarState {
  isHoveredSidebar: boolean;
  selectedSidebarButton: string;
}

// initial state.
export const initialState: SidebarState = {
  isHoveredSidebar: false,
  selectedSidebarButton: 'home',
};

// slice.
export const uiSidebarSlice = createSlice({
  name: 'uiSidebarSlice',
  initialState,
  reducers: {
    hoverSidebar: state => {
      state.isHoveredSidebar = true;
    },
    unhoverSidebar: state => {
      state.isHoveredSidebar = false;
    },
    selectSidebarButton: (state, action: PayloadAction<string>) => {
      state.selectedSidebarButton = action.payload;
    },
    setDefaultState: () => {
      return initialState;
    },
  },
});

export const {
  hoverSidebar,
  unhoverSidebar,
  selectSidebarButton,
  setDefaultState,
} = uiSidebarSlice.actions;

export default uiSidebarSlice.reducer;

// selectors.
export const getIsHoveredSidebar = (state: RootState) =>
  state.uiSidebar.isHoveredSidebar;

export const getSelectedSidebarButton = (state: RootState) =>
  state.uiSidebar.selectedSidebarButton;
