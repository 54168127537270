// libraries.
import { electricPurple600 } from '@habitech/shared';
import { DrawingManager } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';

// slice,
import {
  createPolygon,
  getIsDrawingPolygon,
} from '../store/slices/polygon-slice';
import { polygonRefs } from '../utils';

export const DrawingPolygon = () => {
  const dispatch = useDispatch();
  const isDrawing = useSelector(getIsDrawingPolygon);

  const drawingManagerRef = useRef<DrawingManager | null>(null);
  const [drawingMode, setDrawingMode] =
    useState<google.maps.drawing.OverlayType | null>(null);

  const onPolygonComplete = useCallback(
    (polygons: google.maps.Polygon) => {
      const polygon = polygons
        .getPath()
        .getArray()
        .map(coord => ({
          lat: coord.lat(),
          lng: coord.lng(),
        }));

      const newPolygon = {
        polygon,
      };

      dispatch(createPolygon(newPolygon));
      polygonRefs.push(polygons);
    },
    [dispatch]
  );

  useEffect(() => {
    if (isDrawing) {
      setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    } else {
      setDrawingMode(null);
    }
  }, [isDrawing]);

  return (
    <>
      {isDrawing && (
        <DrawingManager
          ref={drawingManagerRef}
          onPolygonComplete={onPolygonComplete}
          drawingMode={drawingMode}
          options={{
            drawingControl: false,
            polygonOptions: {
              fillColor: electricPurple600,
              fillOpacity: 0.5,
              strokeColor: electricPurple600,
              strokeOpacity: 0.8,
              strokeWeight: 2,
            },
          }}
        />
      )}
    </>
  );
};
