import {
  blueLinks,
  robotoRegular,
  scarpaGray50,
  scarpaGray500,
  skyBlue50,
} from '@habitech/shared';
import { styled } from 'styled-components';

export const WrapperPolygons = styled.div`
  font-size: 14px;
  font-family: ${robotoRegular};
  .title {
    font-weight: 600;
    background: ${scarpaGray50};
  }
  .polygons {
    color: ${scarpaGray500};
    font-weight: 400;
  }
  .id-polygon {
    color: ${scarpaGray500};
  }
  .hovered-card {
    background: ${skyBlue50};
    color: ${blueLinks};
  }
`;
