import {
  electricPurple600,
  electricPurple700,
  error,
  scarpaGray500,
  scarpaGray600,
  skyBlue600,
  skyBlue700,
} from '@habitech/shared';

export const optionsPolygons = {
  fillColor: skyBlue600,
  fillOpacity: 0.4,
  strokeColor: skyBlue700,
  strokeWeight: 2,
  zIndex: 100,
};

export const optionsEditedPolygons = {
  fillColor: electricPurple700,
  fillOpacity: 0.5,
  strokeColor: electricPurple600,
  strokeOpacity: 0.8,
  strokeWeight: 1,
  zIndex: 100,
};

export const optionsDeletePolygon = {
  fillColor: error,
  fillOpacity: 0.5,
  strokeColor: error,
  strokeOpacity: 0.8,
  strokeWeight: 1,
  zIndex: 100,
};

export const optionsHoveredPolygon = {
  fillColor: skyBlue600,
  fillOpacity: 0.8,
  strokeColor: skyBlue700,
  strokeOpacity: 0.8,
  strokeWeight: 1,
  zIndex: 101,
};

export const optionsAdjacentPolygons = {
  fillColor: scarpaGray500,
  fillOpacity: 0.4,
  strokeColor: scarpaGray600,
  strokeWeight: 1,
  zIndex: 100,
};

/*
 * Handle options for polygons.
 */
export const handleOptionsPolygonFromActive = ({
  active,
  edited,
  isHovered,
  median_zone_id,
  medianZoneCode,
}: {
  active?: string;
  edited?: boolean;
  isHovered?: boolean;
  median_zone_id?: string;
  medianZoneCode?: string;
}) => {
  const isActive = active === '1';

  if (!isActive && edited) return optionsDeletePolygon;

  if (isActive && edited) return optionsEditedPolygons;

  if (isHovered) return optionsHoveredPolygon;

  if (median_zone_id?.toString() !== medianZoneCode) {
    return optionsAdjacentPolygons;
  }

  return optionsPolygons;
};
