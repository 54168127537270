import { ButtonLogo, Sidebar } from '@shared';
import { ButtonMedianZone } from './components/ButtonMedianZone';
import { ButtonPolygonsInMedianZone } from './components/ButtonPolygonsInMedianZone';
import { ButtonIntersections } from './components/ButtonIntersections';

export const SidebarPolygons = () => {
  return (
    <Sidebar
      upperButtons={
        <>
          <ButtonLogo />
          <ButtonMedianZone />
          <ButtonPolygonsInMedianZone />
          <ButtonIntersections />
        </>
      }
    />
  );
};
