// libraries.
import { useDispatch } from 'react-redux';

// styles.
import { electricPurple800 } from '@habitech/shared';
import { hoverSidebar, unhoverSidebar } from './store/slices/sidebar-slice';

export const Sidebar = ({
  upperButtons,
  lowerButtons,
}: {
  upperButtons?: JSX.Element;
  lowerButtons?: JSX.Element;
}) => {
  const dispatch = useDispatch();

  return (
    <div
      className="h-100 text-white p-3 d-flex flex-column justify-content-between"
      style={{ backgroundColor: electricPurple800 }}
      onMouseEnter={() => {
        dispatch(hoverSidebar());
      }}
      onMouseLeave={() => {
        dispatch(unhoverSidebar());
      }}
      data-testid="sidebar"
    >
      <div className="d-flex flex-column gap-3">{upperButtons}</div>
      <div className="d-flex flex-column gap-3">{lowerButtons}</div>
    </div>
  );
};

export { ButtonLogo } from './components/ButtonLogo';
export { Button } from './components/Button';
