// libraries.
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store';
import { latLngArrayToGeoJSON } from '@domains/turf';

// interfaces.
import { PolygonObject } from '../api-slices/getPolygons.endpoints';
import {
  findAllIntersections,
  PolygonIntersection,
} from '@domains/turf/utils/findNIntersections';
import { IntersectionListHandler } from '@domains/save-polygons';
import { updateIntersectionListHandler } from './utils/handlerIntersectionsList';

export enum ModePolygon {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create',
  DELETE = 'delete',
}

export interface InitialStateModePolygon {
  modePolygon: ModePolygon;
  listPolygons: PolygonObject[] | [];
  hoveredPolygonId: string | null;
  intersectionsPolygon: PolygonIntersection[] | [];
  intersectionListHandler: IntersectionListHandler[];
}

export const initialState: InitialStateModePolygon = {
  modePolygon: ModePolygon.VIEW,
  listPolygons: [],
  hoveredPolygonId: null,
  intersectionsPolygon: [],
  intersectionListHandler: [],
};

// slice.
export const domainModePolygonSlice = createSlice({
  name: 'domainModePolygon',
  initialState,
  reducers: {
    setModePolygon: (state, action: PayloadAction<ModePolygon>) => {
      state.modePolygon = action.payload;
    },
    setListPolygons: (state, action: PayloadAction<PolygonObject[] | []>) => {
      const newListPolygons = action.payload.map(polygon => {
        const turfPolygon = latLngArrayToGeoJSON(polygon.polygon);
        return {
          ...polygon,
          turf_polygon: turfPolygon,
        };
      });
      const calculateIntersections = findAllIntersections(newListPolygons);

      state.listPolygons = newListPolygons;
      state.intersectionsPolygon = calculateIntersections;

      state.intersectionListHandler = updateIntersectionListHandler(
        state.intersectionsPolygon,
        state.intersectionListHandler
      );
    },
    updateIntersectionListHandlerItem: (
      state,
      action: PayloadAction<{
        idsPolygonString: string;
        data: Partial<IntersectionListHandler>;
      }>
    ) => {
      const { idsPolygonString, data } = action.payload;
      const index = state.intersectionListHandler.findIndex(
        item => item.idsPolygonString === idsPolygonString
      );
      if (index !== -1) {
        state.intersectionListHandler[index] = {
          ...state.intersectionListHandler[index],
          ...data,
        };
      }
    },
    updateIntersectionListHandlerFromServerData: (
      state,
      action: PayloadAction<IntersectionListHandler[] | []>
    ) => {
      const serverData = action.payload;

      state.intersectionListHandler = serverData;
    },
    updateIntersectionHandlerListDirectly: state => {
      state.intersectionListHandler = updateIntersectionListHandler(
        state.intersectionsPolygon,
        state.intersectionListHandler
      );
    },
    setPathPolygonByPolygonId: (
      state,
      action: PayloadAction<{ polygonId: string; path: any; agent: string }>
    ) => {
      const { polygonId, path } = action.payload;
      const index = findIndexPolygonByPolygonIdFromPolygons(
        state.listPolygons,
        polygonId
      );

      if (index !== -1) {
        const turfPolygon = latLngArrayToGeoJSON(path);

        state.listPolygons[index].polygon = path;
        state.listPolygons[index].created_by_agent = action.payload.agent;
        state.listPolygons[index].edited = true;
        state.listPolygons[index].turf_polygon = turfPolygon;
      }
      const calculateIntersections = findAllIntersections(state.listPolygons);
      state.intersectionsPolygon = calculateIntersections;

      state.intersectionListHandler = updateIntersectionListHandler(
        state.intersectionsPolygon,
        state.intersectionListHandler
      );
    },
    deletePolygonByPolygonId: (
      state,
      action: PayloadAction<{ polygonId: string; agent: string }>
    ) => {
      const { polygonId, agent } = action.payload;
      const index = findIndexPolygonByPolygonIdFromPolygons(
        state.listPolygons,
        polygonId
      );
      if (index !== -1) {
        const active = state.listPolygons[index].active;
        state.listPolygons[index].active = active === '1' ? '0' : '1';
        state.listPolygons[index].edited = active === '1' ? true : false;
        state.listPolygons[index].created_by_agent = agent;
      }
    },
    selectPolygonByPolygonId: (state, action: PayloadAction<string>) => {
      const polygonId = action.payload;
      const index = findIndexPolygonByPolygonIdFromPolygons(
        state.listPolygons,
        polygonId
      );
      if (index !== -1) {
        state.listPolygons.forEach(polygon => {
          polygon.select = false;
        });
        state.listPolygons[index].select = true;
      }
    },
    deselectPolygon: state => {
      state.listPolygons.forEach(polygon => {
        polygon.select = false;
      });
    },
    setHoveredPolygonId: (state, action: PayloadAction<string | null>) => {
      state.hoveredPolygonId = action.payload;
    },
    updatePolygonCommentById: (
      state,
      action: PayloadAction<{ polygonId: string; comment: string }>
    ) => {
      const { polygonId, comment } = action.payload;
      const index = state.listPolygons.findIndex(
        polygon => polygon.polygon_id === polygonId
      );
      if (index !== -1) {
        state.listPolygons[index].comment = comment;
        state.listPolygons[index].edited = true;
      }
    },
    setDefaultState: () => {
      return initialState;
    },
    resetIntersectionListHandler: state => {
      state.intersectionListHandler = [];
    },
  },
});

export const {
  setModePolygon,
  setListPolygons,
  setPathPolygonByPolygonId,
  updateIntersectionListHandlerItem,
  updateIntersectionListHandlerFromServerData,
  updateIntersectionHandlerListDirectly,
  deletePolygonByPolygonId,
  selectPolygonByPolygonId,
  deselectPolygon,
  setHoveredPolygonId,
  updatePolygonCommentById,
  setDefaultState,
  resetIntersectionListHandler,
} = domainModePolygonSlice.actions;

export default domainModePolygonSlice.reducer;

// selectors.
export const getModePolygon = (state: RootState) =>
  state.domainModePolygon.modePolygon;

export const getIntersectionsPolygon = (state: RootState) =>
  state.domainModePolygon.intersectionsPolygon;

export const getIntersectionListHandler = (state: RootState) =>
  state.domainModePolygon.intersectionListHandler;

export const getIsModePolygonEdit = (state: RootState) =>
  state.domainModePolygon.modePolygon === ModePolygon.EDIT;

export const getIsModePolygonView = (state: RootState) =>
  state.domainModePolygon.modePolygon === ModePolygon.VIEW;

export const getIsHoveredPolygon = (state: RootState) =>
  state.domainModePolygon.hoveredPolygonId;

// functions.
export const findIndexPolygonByPolygonIdFromPolygons = (
  polygons: PolygonObject[],
  polygonId: string
) => {
  return polygons.findIndex(polygon => polygon.polygon_id === polygonId);
};
