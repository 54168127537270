// libraries.
import { ButtonGoogle } from '@domains/user';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// styles.
import { IconHabiPricing } from '@assets/icons';
import { ImageHero } from '@assets/images';
import { WrapperLogin } from './styles';

export const LoginPage = () => {
  const navigate = useNavigate();

  const loginOnSuccess = () => {
    navigate('/home');
  };

  return (
    <Container fluid className="p-0">
      <div className="d-flex">
        <div className="p-0">
          <ImageHero style={{ height: '100vh' }} />
        </div>
        <div className="d-flex align-items-center justify-content-center w-100 mb-5">
          <WrapperLogin className="mb-5">
            <div className="d-flex justify-content-center mb-5">
              <IconHabiPricing />
            </div>
            <div className="d-flex flex-column align-items-center gap-3 mb-5">
              <div className="title">Polynator</div>
              <div className="google">
                Inicia sesión con tu <br /> cuenta de Google
              </div>
              <ButtonGoogle onSuccess={loginOnSuccess} />
            </div>
          </WrapperLogin>
        </div>
      </div>
    </Container>
  );
};
