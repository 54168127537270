// libraries.
import { toast } from 'react-toastify';
import { PolygonF } from '@react-google-maps/api';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

// hooks.
import { useGetMedianZonesQuery } from '@domains/median-zones/store/api-slices/medianZones.endpoint';

// utils.
import { optionsByOriginZone } from './utils/originZone';

// selectors.
import {
  getMedianZoneCode,
  getShowPolygons,
  setMedianZoneCode,
  setShowPolygons,
} from '@domains/median-zones/store/slices/medianZones.slice';
import { getCountry } from '@domains/user/store/slices/user-slice/user-slice';

export const PolygonsInMedianZones = () => {
  const dispatch = useDispatch();

  const medianZoneCode = useSelector(getMedianZoneCode);
  const showPolygons = useSelector(getShowPolygons);
  const country = useSelector(getCountry);

  const {
    data: medianZonesPolygon,
    isFetching,
    isError,
  } = useGetMedianZonesQuery(
    {
      country,
      idMedianZone: medianZoneCode,
      radius: 2000,
    },
    { skip: !medianZoneCode }
  );

  useEffect(() => {
    if (isFetching) {
      toast.loading('Cargando zonas medianas...', {
        toastId: 'loadingMedianZones',
      });
    } else {
      toast.dismiss('loadingMedianZones');
    }
  }, [isFetching]);

  useEffect(() => {
    if (isError) {
      toast.error('Error obteniendo zonas medianas');
    }
  }, [isError]);

  return (
    <>
      {medianZonesPolygon?.map(polygon => {
        const optionsPolygon = optionsByOriginZone(
          polygon.origin_zone as 0 | 1
        );

        return (
          <PolygonF
            key={polygon.id}
            path={polygon.polygon.map(point => ({
              lat: point.lat,
              lng: point.lng,
            }))}
            options={{
              ...optionsPolygon,
              clickable: !showPolygons,
            }}
            onClick={() => {
              dispatch(setMedianZoneCode(polygon.id.toString()));
              dispatch(setShowPolygons(true));
            }}
          />
        );
      })}
    </>
  );
};
