// Slices
import {
  CountryCode,
  countryCodes,
  getCountryCodeFromRoles,
  ICountrySelect,
  selectForCountries,
} from '@domains/country';

// store.
import { apiAuth } from '.';

export interface IResponseAuthTransformed {
  countriesByUser: CountryCode[];
  countriesSelect: ICountrySelect[];
  roles: string[];
}

export interface AuthResponse {
  role: string[];
  success: boolean;
}

export const auth = apiAuth.injectEndpoints({
  endpoints: builder => ({
    auth: builder.mutation({
      query: ({ userId }: { userId: string }) => ({
        body: { userId },
        method: 'POST',
        url: '/get_cognito_user_role',
      }),
      transformResponse: (response: AuthResponse): IResponseAuthTransformed => {
        const countriesByUser = getCountryCodeFromRoles(response.role);
        const countriesSelect = selectForCountries(countriesByUser);
        const roles = response.role.filter(
          role => !countryCodes.includes(role as CountryCode)
        );

        return { countriesByUser, countriesSelect, roles };
      },
    }),
  }),
});

export const { useAuthMutation } = auth;
