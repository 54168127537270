// libraries.
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// api.
import { apiAcquisitionsPortal } from './apiSlices/apiSlices';

// domains.
import { apiAuth, domainUserAuthSlice } from '@domains/user';
import { domainPolygonToolSlice } from '@domains/polygon-tool';
import { domainMedianZonesSlice } from '@domains/median-zones';
import { domainModePolygonSlice } from '@domains/save-polygons';
import { domainIntersectionsSlice } from '@domains/intersections';

// ui.
import { uiSidebarSlice } from '@shared/Sidebar/store/slices/sidebar-slice';

const persistConfig = {
  key: 'store_global',
  storage,
  whitelist: ['domainUserAuth'],
};

export const rootReducer = combineReducers({
  // apis.
  [apiAcquisitionsPortal.reducerPath]: apiAcquisitionsPortal.reducer,
  [apiAuth.reducerPath]: apiAuth.reducer,
  // domains
  domainUserAuth: domainUserAuthSlice.reducer,
  domainPolygonTool: domainPolygonToolSlice.reducer,
  domainMedianZones: domainMedianZonesSlice.reducer,
  domainModePolygon: domainModePolygonSlice.reducer,
  domainIntersections: domainIntersectionsSlice.reducer,
  // ui
  uiSidebar: uiSidebarSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// middlewares.
export const middlewares = [
  apiAcquisitionsPortal.middleware,
  apiAuth.middleware,
];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        inmutableCheck: false,
        serializableCheck: false,
      },
    }).concat(middlewares),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
