// libraries.
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

// slices.
import {
  getIsModalOpen,
  closeModal,
  setComment,
  getPolygons,
} from '@domains/polygon-tool/store/slices/polygon-slice';

// styles.
import { WrapperModal } from './styles';

export const PolygonCommentModal = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(getIsModalOpen);
  const polygons = useSelector(getPolygons);

  const lastPolygonIndex = polygons.length - 1;

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleSave = () => {
    handleClose();
  };

  const handleComment = (newComment: string) => {
    dispatch(setComment({ index: lastPolygonIndex, comment: newComment }));
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <WrapperModal>
        <Modal.Header className="d-flex justify-content-center p-0 py-2 border-bottom-0">
          <Modal.Title>Comentario</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 pb-3">
          <Form>
            <Form.Group
              className="d-flex flex-column"
              controlId="commentTextarea"
            >
              <Form.Label className="text-center">
                Deja un comentario del polígono que acabas de crear (opcional)
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={e => handleComment(e.target.value)}
                placeholder="Escribir..."
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="flex-nowrap p-0 pb-1 border-top-0">
          <Button className="btn-habi-white w-100" onClick={handleClose}>
            Omitir
          </Button>
          <Button className="btn-habi w-100" onClick={handleSave}>
            Guardar
          </Button>
        </Modal.Footer>
      </WrapperModal>
    </Modal>
  );
};
