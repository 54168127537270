import React, { ReactNode, useMemo, createContext, useRef } from 'react';
import {
  GoogleMap as GoogleMapComponent,
  Libraries,
  LoadScriptNext,
} from '@react-google-maps/api';

import { mapOptions } from './googleMaps.utils';

interface GoogleMapProps {
  children?: ReactNode;
  containerStyle?: React.CSSProperties;
  defaultCenter?: google.maps.LatLngLiteral;
  defaultMapOptions?: google.maps.MapOptions;
  defaultZoom?: number;
  libraries?: Libraries;
}

interface MapContextProps {
  defaultCenter: google.maps.LatLngLiteral;
  defaultZoom: number;
  mapRef: React.MutableRefObject<google.maps.Map | undefined>;
}

export const MapContext = createContext<MapContextProps | null>(null);
const { Provider } = MapContext;

const defaultContainerStyle: React.CSSProperties = {
  height: '400px',
  width: '400px',
};

const center: google.maps.LatLngLiteral = {
  lat: -3.745,
  lng: -38.523,
};

const GoogleMap: React.FC<GoogleMapProps> = ({
  defaultCenter = center,
  defaultZoom = 10,
  containerStyle = defaultContainerStyle,
  defaultMapOptions = mapOptions,
  children,
  libraries = ['drawing'],
}) => {
  const mapRef = useRef<google.maps.Map | undefined>();

  const apiKey = process.env.ENV_GOOGLE_MAPS_API_KEY;

  const mapContainerStyle = useMemo(
    () => ({
      ...defaultContainerStyle,
      ...containerStyle,
    }),
    [containerStyle]
  );

  const centerMemo = useMemo(
    () => ({
      ...center,
      ...defaultCenter,
    }),
    [defaultCenter]
  );

  const mapContextValue: MapContextProps = useMemo(
    () => ({
      defaultCenter,
      defaultZoom,
      mapRef,
    }),
    [defaultCenter, defaultZoom, mapRef.current]
  );

  if (!apiKey) {
    return (
      <div className="text-danger fw-bold">
        Google Maps API key not found. Please check your environment variables.
      </div>
    );
  }

  return (
    <Provider value={mapContextValue}>
      <LoadScriptNext googleMapsApiKey={apiKey} libraries={libraries}>
        <GoogleMapComponent
          data-testid="map-container"
          mapContainerStyle={mapContainerStyle}
          center={centerMemo}
          zoom={defaultZoom}
          options={defaultMapOptions}
          onLoad={map => {
            mapRef.current = map;
          }}
        >
          {children}
        </GoogleMapComponent>
      </LoadScriptNext>
    </Provider>
  );
};

export default React.memo(GoogleMap);
