// libraries.
import { Accordion } from 'react-bootstrap';

// components.
import { NidAndCoordsInputs } from './NidAndCoordsInputs/NidAndCoordsInputs';

// styles.
import { WrapperSearchMedianZones } from './styles';

export const SearchMedianZones = () => {
  return (
    <WrapperSearchMedianZones>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Buscar por NID / Coordenadas</Accordion.Header>
          <Accordion.Body>
            <NidAndCoordsInputs />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </WrapperSearchMedianZones>
  );
};
