// libraries.
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'react-bootstrap';

// domain save-polygons.
import {
  deletePolygonByPolygonId,
  getIsModePolygonEdit,
} from '@domains/save-polygons';

// domain user.
import { getEmail } from '@domains/user/store/slices/user-slice/user-slice';

export const ButtonDeletePolygon = ({ polygonId }: { polygonId: string }) => {
  const dispatch = useDispatch();

  const isModeEdit = useSelector(getIsModePolygonEdit);
  const agent = useSelector(getEmail);

  const handleDeletePolygon = () => {
    dispatch(deletePolygonByPolygonId({ polygonId, agent }));
  };

  if (!isModeEdit) return null;

  return (
    <Badge
      className="bg-danger text-white"
      onClick={handleDeletePolygon}
      role="button"
    >
      Delete
    </Badge>
  );
};
