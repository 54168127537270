// libraries.
import { CountryCode } from '@domains/country';
import { useSelector } from 'react-redux';

// interfaces.
import { RootState } from '@store';

// assets.
import flagMX from './assets/mexico.png';
import flagCO from './assets/colombia.png';

export const Country = ({ code }: { code?: CountryCode }) => {
  const { country } = useSelector((state: RootState) => state.domainUserAuth);

  const selectFlag = (country: CountryCode) => {
    switch (country) {
      case CountryCode.MX:
        return flagMX;
      case CountryCode.CO:
        return flagCO;
      default:
        return flagMX;
    }
  };

  if (!country) return null;

  return (
    <img
      width={22}
      height={22}
      src={selectFlag(code || country)}
      alt={country}
    />
  );
};
