// libraries.
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// slice.
import { setDefaultState } from '@domains/user/store/slices/user-slice/user-slice';

// components.
import { ButtonSidebar } from '@shared';

// assets.
import { IconLogOut } from '@assets/icons';

export const LogOutButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setDefaultState());
    navigate('/', { replace: true });
  };

  return (
    <ButtonSidebar
      text="Cerrar sesión"
      icon={<IconLogOut />}
      onClick={handleClick}
    />
  );
};
