// libraries.
import { Badge } from 'react-bootstrap';
import { styled } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { scarpaGray300, turquoise600 } from '@habitech/shared';

// domains.
import { updateIntersectionListHandlerItem } from '@domains/save-polygons';
import { getEmail } from '@domains/user/store/slices/user-slice/user-slice';
import {
  deselectPolygon,
  getIsHoveredPolygon,
  selectPolygonByPolygonId,
} from '@domains/save-polygons/store/slices/modePolygon.slice';

const WrapperBadge = styled.div`
  .clicked,
  .hovered {
    background-color: ${turquoise600};
    color: white;
  }
  .disabled {
    background-color: ${scarpaGray300};
    color: white;
  }
  &:hover .badge {
    background-color: ${turquoise600};
    color: white;
  }
`;

export const InterceptionBadge = ({
  id,
  idsPolygons,
  priorityPolygon,
}: {
  id: string;
  idsPolygons: string;
  priorityPolygon: string;
}) => {
  const dispatch = useDispatch();
  const agent = useSelector(getEmail);
  const hoveredPolygonId = useSelector(getIsHoveredPolygon);

  const idIsPriority = id + '' === priorityPolygon;
  const isHovered = id === hoveredPolygonId;
  const getVariant = (idIsPriority: boolean, isHovered: boolean) => {
    if (idIsPriority) return 'clicked';
    if (isHovered) return 'hovered';
    return 'disabled';
  };
  const variant = getVariant(idIsPriority, isHovered);

  const handleUpdatePriority = (
    idsPolygonString: string,
    newPriorityId: string
  ) => {
    dispatch(
      updateIntersectionListHandlerItem({
        idsPolygonString,
        data: { priorityPolygonId: newPriorityId + '', agent },
      })
    );
  };

  return (
    <WrapperBadge>
      <Badge
        bg=""
        className={variant}
        role="button"
        onClick={() => handleUpdatePriority(idsPolygons, id)}
        onMouseEnter={() => dispatch(selectPolygonByPolygonId(id))}
        onMouseLeave={() => dispatch(deselectPolygon())}
      >
        {id}
      </Badge>
    </WrapperBadge>
  );
};
