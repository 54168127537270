// libraries.
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// components.
import { ButtonSidebar } from '@shared';
import { IconToolPolygon } from '@assets/icons';
import { selectSidebarButton } from '@shared/Sidebar/store/slices/sidebar-slice';
import { SidebarPolygonTool } from '@pages/PolygonPage/modules/PolygonsDashboard/interfaces/sideBar.types';

export const ButtonToolPolygon = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(selectSidebarButton(SidebarPolygonTool.polygonTool));
    navigate('/polygons/dashboard-tools');
  };

  return (
    <ButtonSidebar
      icon={<IconToolPolygon />}
      text="Polygon Tool"
      onClick={handleClick}
    />
  );
};
