// libraries.
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';

// routes.
import { RouterApp } from '@routes/router';

// store.
import { store, persistor } from '@store';

// styles.
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalStyle } from './styles';

const App = () => {
  return (
    <>
      <ToastContainer />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalStyle />
          <RouterApp />
        </PersistGate>
      </Provider>
    </>
  );
};
const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
