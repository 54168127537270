// libraries.
import { useNavigate } from 'react-router-dom';

// components.
import { ButtonSidebar } from '@shared';
import { IconToolPolygon } from '@assets/icons';

export const ButtonPolygonViewer = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/polygon-viewer/polygon-viewer-dashboard');
  };

  return (
    <ButtonSidebar
      icon={<IconToolPolygon />}
      text="Polygon Viewer"
      onClick={handleClick}
    />
  );
};
