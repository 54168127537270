// libraries.
import { styled } from 'styled-components';
import { useSelector } from 'react-redux';

// slices.
import { getIsDrawingPolygon } from '@domains/polygon-tool/store/slices/polygon-slice';

// assets.
import { IconEdit } from '@assets/icons';
import {
  electricPurple000,
  electricPurple800,
  robotoRegular,
} from '@habitech/shared';

const WrapperEditorMode = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: ${electricPurple800};
  padding: 8px 14px;
  border-radius: 8px;
  color: ${electricPurple000};
  font-family: ${robotoRegular};
  font-size: 12px;
  font-weight: 600;
`;

export const EditorMode = () => {
  const isDrawing = useSelector(getIsDrawingPolygon);

  return (
    isDrawing && (
      <WrapperEditorMode>
        <IconEdit />
        <p>Modo: Editor de polígono</p>
      </WrapperEditorMode>
    )
  );
};
