// libraries.
import { useSelector, useDispatch } from 'react-redux';
import { error, scarpaGray200 } from '@habitech/shared';

// selectors.
import {
  cancelDrawing,
  deletePolygon,
  getPolygons,
} from '../store/slices/polygon-slice';
import { getModePolygon } from '@domains/save-polygons';

// assets.
import { IconDelete } from '@assets/icons';

// utils.
import { polygonRefs } from '../utils';
import { ModePolygon } from '@domains/save-polygons/store/slices/modePolygon.slice';

export const DeletePolygonButton = () => {
  const dispatch = useDispatch();
  const polygons = useSelector(getPolygons);
  const modePolygon = useSelector(getModePolygon);

  const isCreateMode = modePolygon === ModePolygon.CREATE;
  const showButton = polygons.length > 0 && isCreateMode;
  const isDisabled = polygons.length > 0 ? error : scarpaGray200;

  const handleDeletePolygon = () => {
    polygonRefs.forEach(polygon => {
      if (polygon) {
        polygon.setMap(null);
      }
    });
    polygonRefs.length = 0;
    dispatch(deletePolygon());
    dispatch(cancelDrawing());
  };

  return (
    <>
      {showButton && (
        <div className="delete" onClick={handleDeletePolygon}>
          <IconDelete fill={isDisabled} />
        </div>
      )}
    </>
  );
};
