// libraries.
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// selectors.
import {
  getCountry,
  getEmail,
  getRole,
  setRole,
} from '@domains/user/store/slices/user-slice/user-slice';

// services.
import {
  useGetRolesPolynatorQuery,
  RolesPolynatorEnum,
} from '@domains/user/store/api-slices/roles-polynator.endpoint';

export const useRoleForPolynator = () => {
  const dispatch = useDispatch();

  const country = useSelector(getCountry)!;
  const email = useSelector(getEmail);
  const rolePolynator = useSelector(getRole);

  const isPolynatorRole =
    rolePolynator === RolesPolynatorEnum.PolygonCreator ||
    rolePolynator === RolesPolynatorEnum.PolygonViewer;

  const shouldFetch = country && email && !isPolynatorRole;

  const { data, isLoading, isSuccess, isError } = useGetRolesPolynatorQuery(
    {
      country: country,
      email: email,
    },
    {
      skip: !shouldFetch,
    }
  );

  const role = data?.role;

  useEffect(() => {
    if (role) {
      dispatch(setRole(role));
    }
  }, [data]);

  useEffect(() => {
    if (isLoading) {
      toast.loading('Validando Rol..', { toastId: 'RoleForPolynator' });
    } else {
      toast.dismiss('RoleForPolynator');
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess) {
      const isCreator = role === RolesPolynatorEnum.PolygonCreator;
      toast.success(
        `Rol ${isCreator ? 'creador de polígonos' : 'validador de polígonos'}`
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error('Error al obtener el rol, contacte a sistemas :V');
    }
  }, [isError]);
};
