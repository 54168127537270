// libraries.
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';

// domains.
import { getEmail } from '@domains/user/store/slices/user-slice/user-slice';
import {
  getIsModePolygonEdit,
  updateIntersectionListHandlerItem,
} from '@domains/save-polygons';
import { getIsModePolygonView } from '@domains/save-polygons/store/slices/modePolygon.slice';

export const InterceptionComment = ({
  idsPolygonString,
  comment,
}: {
  idsPolygonString: string;
  comment?: string;
}) => {
  const dispatch = useDispatch();
  const agent = useSelector(getEmail);
  const isEditing = useSelector(getIsModePolygonEdit);
  const isViewing = useSelector(getIsModePolygonView);

  const handleUpdateComment = (
    idsPolygonString: string,
    newComment: string
  ) => {
    dispatch(
      updateIntersectionListHandlerItem({
        idsPolygonString,
        data: { comment: newComment, agent },
      })
    );
  };
  return isEditing || isViewing ? (
    <Form.Control
      as="textarea"
      placeholder="Ingresa tu comentario"
      className="w-100"
      style={{ height: '50px', fontSize: '14px' }}
      value={comment}
      onChange={e => handleUpdateComment(idsPolygonString, e.target.value)}
    />
  ) : (
    <p>{comment || 'No hay comentario'}</p>
  );
};
