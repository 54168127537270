import { robotoRegular, scarpaGray50 } from '@habitech/shared';
import { styled } from 'styled-components';

export const WrapperSearchByNid = styled.div`
  display: flex;
  font-size: 13px;
  font-family: ${robotoRegular};
  font-weight: 800;
  background: ${scarpaGray50};
  .form-control {
    padding: 0.375rem 0.75rem !important;
    font-size: 13px !important;
    font-family: ${robotoRegular};
  }
`;

export const WrapperSearchMedianZones = styled.div`
  font-size: 14px;
  .accordion-body {
    padding: 14px;
    background-color: ${scarpaGray50};
  }
  .accordion-button {
    padding: 16px;
    font-size: 14px;
    font-family: ${robotoRegular};
    font-weight: 800;
    background: ${scarpaGray50};
    display: flex;
    justify-content: space-between;
  }
  .accordion-button::after {
    margin-left: 0;
  }
  .accordion-button:not(.collapsed) {
    background-color: ${scarpaGray50};
  }
  .accordion-button:focus {
    box-shadow: none;
  }
  .form-control {
    font-size: 14px;
    padding: 5px;
  }
`;
